import { App } from 'vue';
import { LocalStorageKeys } from '@/project/localStorageKeys';
import DictionaryLabel from './DictionaryLabel.vue';

declare global {
    interface Window {
        dictionaryMap: Map<string, string>;
        languageCode: string;
        missingLabelsQueue: Array<
        { 
            /** @minLength 1 */
            key: string;
            /** @minLength 1 */
            language: string;
            /** @minLength 1 */
            location: string; 
        }>
    }
}

declare module '@vue/runtime-core' {
    interface ComponentCustomProperties {
        $translate: (key: string, ...params: string[]) => string;
        $tryTranslate: (key: string, ...params: string[]) => string;
        $getLanguageCode: (translate?: boolean) => string;
    }
}

function getLanguageFallback() {
    const userLang = (navigator.language || navigator.languages[0]).toLowerCase();
    
    if (userLang.startsWith('da')) 
        return 'Da';
    else if (userLang.startsWith('en'))
        return 'En';

    return 'Da';
}

window.languageCode = window.localStorage.getItem(LocalStorageKeys.LANGUAGE) ?? getLanguageFallback();
window.dictionaryMap ??= new Map<string, string>([ ['Validation.Required', '{0} skal udfyldes'] ]);
window.missingLabelsQueue = [];

class Dictionary {
    private initialized: boolean = false;

    public has(key: string, trackMissingLabel: boolean = false) {
        if (window.dictionaryMap.has(key))
            return true;
        
        if (trackMissingLabel && this.initialized) {
            if (!window.missingLabelsQueue.some(x => x.key === key)) {
                window.missingLabelsQueue.push({ key: key, language: window.languageCode, location: window.location.href });
            }
        }
        return false;
    }

    public get(key: string, ...args: any[]): string {
        const translated = window.dictionaryMap.get(key);
        if (translated === undefined) {
            if (this.initialized)
                window.missingLabelsQueue.push({ key: key, language: window.languageCode, location: window.location.href });
            console.error(`### Key '${key}' not found in dictionary ###`);
            return `##${key}`;
        }

        const formatted = this.format(translated, args);
        const showLabelsDebug = window.location.href.includes('labels_debug');

        return showLabelsDebug ? `${formatted} (${key})` : formatted;
    }

    private format(input: string, args: string[]): string {
        const res = args.reduce((result, arg, ix) => {
            return result.split(`{${ix}}`).join(arg);
        }, input);
        return res;
    }

    public init(labels: { key: string, text: string }[]) {
        for (let i = 0; i < labels.length; i++) {
            const label = labels[i];
            window.dictionaryMap.set(label.key, label.text);
        }

        this.initialized = true;
    }

    public setActiveLanguageCode(code: string, refreshIfChanged = true) {
        window.localStorage.setItem(LocalStorageKeys.LANGUAGE, code);

        if (refreshIfChanged && window.languageCode !== code) {
            window.location.reload();
        }
    }

    public addOrUpdateLabel(key: string, text: string) {
        window.dictionaryMap.set(key, text);
    }
}

const dictionary = new Dictionary();

export function dictionaryConfig(app: App): void {
    app.config.globalProperties.$translate = dictionary.get.bind(dictionary);
    app.config.globalProperties.$tryTranslate = (key: string, ...params: string[]) => dictionary.has(key, false) ? dictionary.get(key, params) : key;
    app.config.globalProperties.$getLanguageCode = (translate = false) => translate ? dictionary.get('Language.' + window.languageCode) : window.languageCode;
    app.component('DLabel', DictionaryLabel);
}

export default dictionary;
