import { defineRule, configure } from 'vee-validate';
// eslint-disable-next-line camelcase
import { required, email, min, max, min_value, max_value, between, is_not, numeric, url, confirmed } from '@vee-validate/rules';
import { FieldContext, ValidationRule } from '@/core/forms/vee-validate.types';
import dictionary from '@/core/dictionary/dictionary';
import { PostalCodeInformation, SearchAddressResponse } from '@/api';
// import { getDecimalSeparator } from '../dom/dom';
import { isValidPhoneNumber } from 'libphonenumber-js';

const decimalSeperator = '.'; //; getDecimalSeparator();

function isValidIpAddress(ipaddress: string) {  
    return ipaddress && /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/.test(ipaddress);  
}  

function isValidGuid(guid: string) {  
    return guid && /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i.test(guid);  
}  

const rules: Record<string, ValidationRule> = {
    required: {
        validator: required,
        label: getLabel('Required'),
    },
    'required-checkbox': {
        validator: required,
        label: getLabel('RequiredCheckbox'),
    },
    email: {
        validator: email,
        label: getLabel('Email'),
    },
    emailOrDomain: {
        validator: (value) => {
            if (typeof value !== 'string') {
                return false;
            }

            if (value.length < 3)
                return false;

            if (value.includes('@'))
                return email(value);
                
            if (!value.startsWith('www') && !value.includes('/') && value.includes('.'))
                return true;
            
            return false;
        },
        label: getLabel('EmailOrDomain'),
    },
    street: {
        validator: (value?: SearchAddressResponse | string) => {
            if (!value)
                return true;

            const street = typeof value === 'string' ? value : value.street;

            if (street.length < 1)
                return 'Indast en gyldig adresse';
            
            if (!(/\d/.test(street))) {
                return 'Addressen skal indeholde et husnummer';
            }
    
            return true;
        },
        label: 'Indast en gyldig adresse',
    },
    city: {
        validator: (value?: PostalCodeInformation | string) => {
            if (!value)
                return true;

            const city = typeof value === 'string' ? value : value.name;
        
            if (city.length < 1)
                return 'Indast en gyldig by';
    
            return true;
        },
        label: 'Indast en gyldig by',
    },
    postalCode: {
        validator: (value?: PostalCodeInformation | string | number) => {
            if (!value)
                return true;

            if (typeof value === 'object' && value.number <= 0)
                return 'Indast et gyldigt postnummer';
            else if (typeof value === 'string' && (value.length < 1 || isNaN(value as any)))
                return 'Indast et gyldigt postnummer';
            else if (typeof value === 'number' && isNaN(value))
                return 'Indast et gyldigt postnummer';
            
            return true;
        },
        label: 'Indast et gyldigt postnummer',
    },
    min: {
        validator: min,
        label: getLabel('Min'),
    },
    max: {
        validator: max,
        label: getLabel('Max'),
    },
    guid: {
        validator: (value, [target], ctx) => {
            if (!value || typeof value !== 'string') {
                return true;
            }

            if (!isValidGuid(value)) {
                return 'Indtast et gyldig ID';
            }

            return true;
        },
        label: 'Indtast et gyldigt ID',
    },
    alpha_spaces: {
        validator: (value, [target], ctx) => {
            if (value === null || value === undefined || value === '') {
                return false;
            }

            return /[a-zA-Z -]+$/.test(value);
        },
        label: getLabel('AlphabeticalWithWhitespace'),
    },
    'is-not': {
        validator: is_not,
        label: getLabel('IsNot'),
    },
    'min-value': {
        validator: min_value,
        label: getLabel('MinValue'),
    },
    'max-value': {
        validator: max_value,
        label: getLabel('MaxValue'),
    },
    between: {
        validator: between,
        label: getLabel('Between'),
    },
    url: {
        validator: url,
        label: getLabel('Url'),
    },
    cvr: {
        validator: (value, [target], ctx) => {
            if (!value || typeof value !== 'string') {
                return true;
            }

            if (value.length !== 8) {
                return 'CVR Skal være præcist 8 tegn';
            }

            if (!(/^\d+$/.test(value))) {
                return 'CVR må kun indeholde tal';
            }

            return true;
        },
        label: getLabel('Cvr'),
    },
    address: {
        validator: (value, [target], ctx) => {
            if (!value || typeof value !== 'string') {
                return true;
            }

            if (!(/\d/.test(value))) {
                return 'Addressen skal indeholde et husnummer';
            }

            return true;
        },
        label: getLabel('Address'),
    },
    phone: {
        validator: (phoneNumber, [target], ctx) => {
            if (!phoneNumber || typeof phoneNumber !== 'string') {
                return true;
            }
            
            return isValidPhoneNumber(phoneNumber);
        },
        label: getLabel('Phone'),
    },
    ipAddress: {
        validator: (value, [target], ctx) => {
            if (!value || typeof value !== 'string') {
                return true;
            }

            if (!isValidIpAddress(value)) {
                return 'Indtast en gyldig IP Addresse';
            }

            return true;
        },
        label: getLabel('IpAddress'),
    },
    numeric: {
        validator: numeric,
        label: getLabel('Numeric'),
    },
    confirmed: {
        validator: confirmed,
        label: getLabel('Confirmed'),
    },
    decimal: {
        validator: (value, { decimals = '*', separator = decimalSeperator } = {}) => {
            if (value === null || value === undefined || value === '') {
                return true;
            }

            if (Number(decimals) === 0) {
                return /^-?\d*$/.test(value);
            }

            const regexPart = decimals === '*' ? '+' : `{1,${decimals}}`;
            const regex = new RegExp(`^[-+]?\\d*(\\${separator}\\d${regexPart})?([eE]{1}[-]?\\d+)?$`);

            return regex.test(value);
        },
        label: getLabel('Decimal'),
    },
    password: {
        validator: (value, [target], ctx) => {
            function hasLength(value: string): boolean {
                return value.length >= 8;
            }

            function hasUppercase(value: string): boolean {
                return !!(value.match(/[A-Z]/)?.length);
            }

            function hasLowercase(value: string): boolean {
                return !!(value.match(/[a-z]/)?.length);
            }

            function hasNumeric(value: string): boolean {
                return !!(value.match(/\d/)?.length);
            }

            if (!value) return false;
            if (!hasLength(value)) return false;
            if (!hasLowercase(value)) return false;
            if (!hasUppercase(value)) return false;
            if (!hasNumeric(value)) return false;

            return true;
        },
        label: getLabel('Password'),
    },
};

configure({
    generateMessage,
});

setupRules();

function getLabel(rule: string): string {
    return `Validation.${rule}`;
}

function setupRules(): void {
    Object.keys(rules).forEach((id: string) => {
        const validator = rules[id].validator;
        defineRule(id, validator);
    });
}

function generateMessage(context: FieldContext): string {
    if (!context.rule) {
        throw new Error(`Validation-error but no rules: ${context.field}`);
    }

    const labelKey = rules[context.rule.name].label;
    const params: string[] = [];
    if (context.rule.params) {
        // Can be array or object with key/values - e.g. { min: xx, max: yy }. Convert to array always.
        params.push(...Array.isArray(context.rule.params) ? context.rule.params : Object.values(context.rule.params));
    }
    return translate(labelKey, context.field, params);
}

function translate(label: string, field: string, params: string[]): string {
    return dictionary.get(label, ...[field, ...params]);
}

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
defineRule('confirmed', (value, [target], ctx) => {
    if (value === ctx.form[target]) {
        return true;
    }
    return 'Passwords must match';
});
