import { AddOrUpdateTaskGridColumnsRequest, BusinessEntityTaskGridColumnsViewModel,
    BusinessEntityTaskGridColumnViewModel, 
    BusinessEntityViewModel, UpdateBusinessEntityRequest, 
    AddOrUpdateShipmentPdfBuilderRequest, AddOrUpdateTaskGridColumnRequest, BusinessEntityShipmentPdfBuilderViewModel,
    BusinessEntityShipmentPdfBuildersViewModel, BusinessEntityTaskGridColumnLayoutsViewModel, BusinessEntityTaskGridColumnLayoutViewModel,
    AddOrUpdateTaskGridColumnLayoutRequest, BusinessEntityCsvExportConfigurationsViewModel, BusinessEntityCsvExportConfigurationViewObject, 
    AddOrUpdateCsvExportConfigurationRequest, BusinessEntityDeliveryCapacityThresholdViewModel, BusinessEntityDeliveryCapacityThresholdViewObject,
    AddOrUpdateDeliveryCapacityThresholdRequest,
    DriverTaskOverviewPdfBuilderViewModel,
    DriverTaskOverviewPdfBuildersViewModel,
    AddOrUpdateDriverTaskOverviewPdfBuilderRequest,
    BusinessEntityUserLocationTrackingLogViewModel,
    GetDriverLogsRequest,
    BusinessEntityDriverLogsViewModel,
    DeliveryDeviationTypeViewModel,
    AddOrUpdateDeliveryDeviationTypeRequest,
    DeliveryDeviationTypeViewObject,
    BusinessEntityDeliveryTaskImportConfigurationsViewModel,
    AddOrUpdateDeliveryTaskImportConfigurationRequest,
    BusinessEntityDeliveryTaskImportConfigurationViewObject,
    BusinessServerContextViewObject,
    BusinessEntityUserDriverLogPreStartCheckViewModel,
    BusinessEntityUserDriverLogPreStartCheckViewObject,
    AddOrUpdateUserDriverLogPreStartCheckRequest,
} from '@/api';
import { apiInstanceFactory } from '@/core/api/api.factory';
import { ApiBase } from '@/core/api/api.base';
import { AxiosProgressEvent } from 'axios';

const api = apiInstanceFactory();

class BusinessEntityService extends ApiBase {
    public async getServerContext(): Promise<BusinessServerContextViewObject | undefined> {
        return this.callEndpointWithErrorHandling(() => api.api.businessEntityGetServerContextList());
    }

    public async updateBusinessEntity(request: UpdateBusinessEntityRequest): Promise<BusinessEntityViewModel | undefined> {
        return this.callEndpointWithErrorHandling(() => api.api.businessEntityUpdateCreate(request));
    }

    public async changeLogo(formFile: File, callback?: (event: AxiosProgressEvent, loadPercentage: number) => void): Promise<BusinessEntityViewModel | undefined> {
        return this.callEndpointWithErrorHandling(() => api.api.businessEntityChangeLogoUpdate({
            formFile,
        }, {
            headers: undefined!,
            onUploadProgress: (event: AxiosProgressEvent) => {
                callback && callback(event, this.calculateLoadPercentage(event));
            },
        }));
    }

    public async getTaskGridColumns(): Promise<BusinessEntityTaskGridColumnsViewModel | undefined> {
        return this.callEndpointWithErrorHandling(() => api.api.businessEntityGetTaskGridColumnsList());
    }
    
    public async addOrUpdateTaskGridColumn(request: AddOrUpdateTaskGridColumnRequest): Promise<BusinessEntityTaskGridColumnViewModel | undefined> {
        return this.callEndpointWithErrorHandling(() => api.api.businessEntityAddOrUpdateTaskGridColumnCreate(request));
    }

    public async addOrUpdateTaskGridColumns(request: AddOrUpdateTaskGridColumnsRequest): Promise<BusinessEntityTaskGridColumnsViewModel | undefined> {
        return this.callEndpointWithErrorHandling(() => api.api.businessEntityAddOrUpdateTaskGridColumnsCreate(request));
    }

    public async deleteTaskGridColumn(columnId: number): Promise<BusinessEntityTaskGridColumnViewModel | undefined> {
        return this.callEndpointWithErrorHandling(() => api.api.businessEntityDeleteTaskGridColumnDelete({ columnId: columnId }));
    }

    public async getTaskGridColumnLayouts(): Promise<BusinessEntityTaskGridColumnLayoutsViewModel | undefined> {
        return this.callEndpointWithErrorHandling(() => api.api.businessEntityGetTaskGridColumnLayoutsList());
    }
    
    public async addOrUpdateTaskGridColumnLayout(request: AddOrUpdateTaskGridColumnLayoutRequest): Promise<BusinessEntityTaskGridColumnLayoutViewModel | undefined> {
        return this.callEndpointWithErrorHandling(() => api.api.businessEntityAddOrUpdateTaskGridColumnLayoutCreate(request));
    }

    public async deleteTaskGridColumnLayout(layoutId: number): Promise<BusinessEntityTaskGridColumnLayoutViewModel | undefined> {
        return this.callEndpointWithErrorHandling(() => api.api.businessEntityDeleteTaskGridColumnLayoutDelete({ layoutId: layoutId }));
    }

    public async addOrUpdateShipmentPdfBuilder(request: AddOrUpdateShipmentPdfBuilderRequest): Promise<BusinessEntityShipmentPdfBuilderViewModel | undefined> {
        return this.callEndpointWithErrorHandling(() => api.api.businessEntityAddOrUpdateShipmentPdfBuilderCreate(request));
    }

    public async getShipmentPdfBuilders(builderId?: string): Promise<BusinessEntityShipmentPdfBuildersViewModel | undefined> {
        return this.callEndpointWithErrorHandling(() => api.api.businessEntityGetShipmentPdfBuildersList({ builderId: builderId }));
    }

    public async deleteShipmentPdfBuilder(builderId: string): Promise<BusinessEntityShipmentPdfBuilderViewModel | undefined> {
        return this.callEndpointWithErrorHandling(() => api.api.businessEntityDeleteShipmentPdfBuilderDelete({ builderId: builderId }));
    }

    public async addOrUpdateDriverTaskOverviewPdfBuilder(request: AddOrUpdateDriverTaskOverviewPdfBuilderRequest): Promise<DriverTaskOverviewPdfBuilderViewModel | undefined> {
        return this.callEndpointWithErrorHandling(() => api.api.businessEntityAddOrUpdateDriverTaskOverviewPdfBuilderCreate(request));
    }

    public async getDriverTaskOverviewPdfBuilders(builderId?: string): Promise<DriverTaskOverviewPdfBuildersViewModel | undefined> {
        return this.callEndpointWithErrorHandling(() => api.api.businessEntityGetDriverTaskOverviewPdfBuildersList({ builderId: builderId }));
    }

    public async deleteDriverTaskOverviewPdfBuilder(builderId: string): Promise<DriverTaskOverviewPdfBuilderViewModel | undefined> {
        return this.callEndpointWithErrorHandling(() => api.api.businessEntityDeleteDriverTaskOverviewPdfBuilderDelete({ builderId: builderId }));
    }

    public async getCsvExportConfigurations(): Promise<BusinessEntityCsvExportConfigurationsViewModel | undefined> {
        return this.callEndpointWithErrorHandling(() => api.api.businessEntityGetCsvExportConfigurationsList());
    }
    
    public async addOrUpdateCsvExportConfiguration(request: AddOrUpdateCsvExportConfigurationRequest): Promise<BusinessEntityCsvExportConfigurationViewObject | undefined> {
        return this.callEndpointWithErrorHandling(() => api.api.businessEntityAddOrUpdateCsvExportConfigurationCreate(request), {
            cancellationKey: 'addOrUpdateCsvExportConfiguration',
        });
    }

    public async deleteCsvExportConfiguration(configurationId: string): Promise<BusinessEntityCsvExportConfigurationViewObject | undefined> {
        return this.callEndpointWithErrorHandling(() => api.api.businessEntityDeleteCsvExportConfigurationDelete({ configurationId }), {
            cancellationKey: 'deleteCsvExportConfiguration',
        });
    }

    public async getDeliveryTaskImportConfigurations(): Promise<BusinessEntityDeliveryTaskImportConfigurationsViewModel | undefined> {
        return this.callEndpointWithErrorHandling(() => api.api.businessEntityGetDeliveryTaskImportConfigurationsList());
    }
    
    public async addOrUpdateDeliveryTaskImportConfiguration(request: AddOrUpdateDeliveryTaskImportConfigurationRequest): Promise<BusinessEntityDeliveryTaskImportConfigurationViewObject | undefined> {
        return this.callEndpointWithErrorHandling(() => api.api.businessEntityAddOrUpdateDeliveryTaskImportConfigurationCreate(request), {
            cancellationKey: 'addOrUpdateDeliveryTaskImportConfiguration',
        });
    }

    public async deleteDeliveryTaskImportConfiguration(configurationId: string): Promise<BusinessEntityDeliveryTaskImportConfigurationViewObject | undefined> {
        return this.callEndpointWithErrorHandling(() => api.api.businessEntityDeleteDeliveryTaskImportConfigurationDelete({ configurationId }), {
            cancellationKey: 'deleteDeliveryTaskImportConfiguration',
        });
    }

    public async getCapacityThresholds(): Promise<BusinessEntityDeliveryCapacityThresholdViewModel | undefined> {
        return this.callEndpointWithErrorHandling(() => api.api.businessEntityGetCapacityThresholdsList());
    }
    
    public async addOrUpdateDeliveryCapacityThreshold(request: AddOrUpdateDeliveryCapacityThresholdRequest): Promise<BusinessEntityDeliveryCapacityThresholdViewObject | undefined> {
        return this.callEndpointWithErrorHandling(() => api.api.businessEntityAddOrUpdateDeliveryCapacityThresholdCreate(request), {
            cancellationKey: 'addOrUpdateDeliveryCapacityThreshold',
        });
    }

    public async deleteCapacityThreshold(capacityId: number): Promise<BusinessEntityDeliveryCapacityThresholdViewObject | undefined> {
        return this.callEndpointWithErrorHandling(() => api.api.businessEntityDeleteCapacityThresholdDelete({ capacityId }), {
            cancellationKey: 'deleteCapacityThreshold',
        });
    }

    public async getDriverLogs(request: GetDriverLogsRequest): Promise<BusinessEntityDriverLogsViewModel | undefined> {
        return this.callEndpointWithErrorHandling(() => api.api.businessEntityGetDriverLogsCreate(request));
    }

    public async getLocationTracking(driverLogId: string): Promise<BusinessEntityUserLocationTrackingLogViewModel | undefined> {
        return this.callEndpointWithErrorHandling(() => api.api.businessEntityGetLocationTrackingList({
            driverLogId: driverLogId,
        }));
    }

    public async getDeliveryDeviations(): Promise<DeliveryDeviationTypeViewModel | undefined> {
        return this.callEndpointWithErrorHandling(() => api.api.businessEntityGetDeliveryDeviationTypesList(), { 
            suppressErrors: true,
        });
    }

    public async addOrUpdateDeliveryDeviationType(request: AddOrUpdateDeliveryDeviationTypeRequest): Promise<DeliveryDeviationTypeViewObject | undefined> {
        return this.callEndpointWithErrorHandling(() => api.api.businessEntityAddOrUpdateDeliveryDeviationTypeCreate(request));
    }

    public async deleteDeliveryDeviationType(deliveryDeviationId: string): Promise<DeliveryDeviationTypeViewObject | undefined> {
        return this.callEndpointWithErrorHandling(() => api.api.businessEntityDeleteDeliveryDeviationTypeDelete({ deliveryDeviationId }));
    }

    public async getUserDriverLogPreStartChecks(): Promise<BusinessEntityUserDriverLogPreStartCheckViewModel | undefined> {
        return this.callEndpointWithErrorHandling(() => api.api.businessEntityGetUserDriverLogPreStartChecksList(), { 
            suppressErrors: true,
        });
    }

    public async addOrUpdateUserDriverLogPreStartCheck(request: AddOrUpdateUserDriverLogPreStartCheckRequest): Promise<BusinessEntityUserDriverLogPreStartCheckViewObject | undefined> {
        return this.callEndpointWithErrorHandling(() => api.api.businessEntityAddOrUpdateUserDriverLogPreStartCheckCreate(request));
    }

    public async addOrUpdateUserDriverLogPreStartCheckOrder(checkIds: string[]): Promise<BusinessEntityUserDriverLogPreStartCheckViewModel | undefined> {
        return this.callEndpointWithErrorHandling(() => api.api.businessEntityAddOrUpdateUserDriverLogPreStartCheckOrdersCreate(checkIds));
    }

    public async deleteUserDriverLogPreStartCheck(checkId: string): Promise<BusinessEntityUserDriverLogPreStartCheckViewObject | undefined> {
        return this.callEndpointWithErrorHandling(() => api.api.businessEntityDeleteUserDriverLogPreStartCheckDelete({ checkId }));
    }
}

export default new BusinessEntityService();
