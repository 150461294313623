import { computed, ComputedRef } from 'vue';
import { BusinessEntityUserRole, EndpointAccessViewObject } from '@/api';
import { Store } from '@/core/store/store';
import { userStore } from '../user/user.store';
import securityApiService from '@/api-controllers/securityApi.service';
import bus from '@/core/bus';

type Hooks = '';

interface SecurityState extends Record<string, unknown> {
    accesses: Map<string, Map<string, EndpointAccessViewObject>>;
}

class SecurityStore extends Store<SecurityState, Hooks> {
    protected data(): SecurityState {
        return {
            accesses: new Map<string, Map<string, EndpointAccessViewObject>>(),
        };
    }

    public get endpointAccesses(): ComputedRef<EndpointAccessViewObject[]> {
        return computed(() => {
            // Get an array of inner maps (Map<string, EndpointAccessViewObject>)
            const innerMaps = Array.from(this.state.accesses.values());
    
            // Flatten the array of inner map values into a single array of EndpointAccessViewObject
            return innerMaps.flatMap(innerMap => Array.from(innerMap.values()));
        });
    }

    public get endpointAccessesMap(): ComputedRef<Map<string, Map<string, EndpointAccessViewObject>>> {
        return computed(() => this.state.accesses);
    }

    public hasAccess(controller: string, action: string): boolean {
        const controllerCache = this.state.accesses.get(controller);
        if (!controllerCache) {
            console.warn(`Unknown EndpointAccess ${controller}.${action} used`);
            return false;
        } 

        const access = controllerCache.get(action);
        if (!access) {
            console.warn(`Unknown EndpointAccess ${controller}.${action} used`);
            return false;
        } 

        const user = userStore.user.value;
        if (!user) {
            return false; 
        }

        return userStore.hasRole(access.minRole);
    }

    public getAccess(controller: string, action: string): EndpointAccessViewObject | undefined {
        const controllerCache = this.state.accesses.get(controller);
        if (!controllerCache) {
            console.warn(`Unknown EndpointAccess ${controller}.${action} used`);
            return;
        } 

        const access = controllerCache.get(action);
        if (!access) {
            console.warn(`Unknown EndpointAccess ${controller}.${action} used`);
            return;
        }
        
        return access;
    }

    public clear() {
        // EndPoint accesses are not user specific so we do not need to clear them between logins only between business entities
    }

    public async init() {
        const result = await securityApiService.getEndpointAccesses();
        if (result) {
            const accessesMap = new Map<string, Map<string, EndpointAccessViewObject>>();

            result.forEach(access => {
                // Retrieve or create the map for the specific controller
                let controllerMap = accessesMap.get(access.controller);
                if (!controllerMap) {
                    controllerMap = new Map<string, EndpointAccessViewObject>();
                    accessesMap.set(access.controller, controllerMap);
                }
    
                // Add the access object to the controller's map
                controllerMap.set(access.action, access);
            });
    
            this.state.accesses = accessesMap;
        }
    }

    constructor() {
        super();

        bus.on('EndpointAccessUpdated', (controller: string, action: string, minRole: BusinessEntityUserRole) => {
            const controllerCache = this.state.accesses.get(controller);
            if (controllerCache) {
                const rule = controllerCache.get(action);
                if (rule) {
                    rule.minRole = minRole;
                }
            }
        });

        bus.on('LOGGED_OUT', () => {
            this.clear();
        });
    }
}

export const securityStore = new SecurityStore();
