import { RequestParams, ValidationMessage } from '@/api';
import bus from '@/core/bus';
import dictionary from '@/core/dictionary/dictionary';
import notificationsService from '@/core/notifications/notifications.service';
import axios, { AxiosError, AxiosProgressEvent, AxiosPromise, CancelToken, CancelTokenSource } from 'axios';
import HttpStatus from 'http-status-codes';
// import { format } from 'date-fns';

interface ClientResponse<T> {
    validationMessages?: ValidationMessage[] | null;
    correlationId?: string | null;
  
    /** @format double */
    serverTimeMs?: number | null;
    model?: T | null;
}

interface RequestOptions {
    cancellationKey?: string;
    suppressErrors?: boolean;
    retries?: number;
    retryTimeout?: number;
    hideLoadingBar?: boolean;
}

export abstract class ApiBase {
    private requestDictionary: { [key: string]: CancelTokenSource | null } = {};

    private ensureCancellationToken(key: string): CancelToken {
        const cancelTokenSrc = this.requestDictionary[key];
        if (cancelTokenSrc) {
            cancelTokenSrc.cancel();
        }

        const newCancelTokenSrc = axios.CancelToken.source();
        this.requestDictionary[key] = newCancelTokenSrc;
        return newCancelTokenSrc.token;
    }

    private removeCancellationToken(key: string) {
        this.requestDictionary[key] = null;
        delete this.requestDictionary[key];
    }

    protected alertOnError(correlationId: string, validationMessages: ValidationMessage[]): void {
        validationMessages.forEach(msg => {
            notificationsService.notify({
                title: 'Fejl',
                message: dictionary.has(msg.message, false) ? dictionary.get(msg.message, ...(msg.args || [])) : msg.message,
                type: 'Error',
                duration: 8000,
            });
        });
    }

    protected alertOnException(e: any): void {
        if (axios.isCancel(e)) {
            return;
        }

        if (e?.response?.data?.correlationId && e.response.data.validationMessages) {
            this.alertOnError(e.response.data.correlationId, e.response.data.validationMessages);
        } else {
            notificationsService.notify({
                title: 'Fejl',
                type: 'Error',
                message: `${e.message} (${e.config?.url})`,
                duration: 8000,
            });
        }

        console.error('HTTP Request Error:\n\n', JSON.stringify(e));
    }

    protected async callEndpointWithErrorHandling<T>(action: (params?: Partial<RequestParams>) => AxiosPromise<ClientResponse<T>>, options?: RequestOptions): Promise<T | undefined> {
        try {
            !options?.hideLoadingBar && bus.emit('PROGRESS_START');

            const { data: { model, correlationId, validationMessages } } = await action({
                ...(options?.cancellationKey && { cancelToken: this.ensureCancellationToken(options.cancellationKey) }),
            });

            if (validationMessages && correlationId && !options?.suppressErrors) {
                this.alertOnError(correlationId, validationMessages);
            }

            if (model == null) {
                return !!validationMessages as any;
            }

            return model;
        } catch (e) {
            if (axios.isAxiosError(e)) {
                if (e.request?.status === HttpStatus.UNAUTHORIZED && !options?.suppressErrors) {
                    this.alertOnException(e as AxiosError<ClientResponse<T>>);
                    return;
                }
            }

            if (options && options.retries && options.retries > 0) {
                options.retries--;
                await new Promise((resolve) => setTimeout(resolve, options.retryTimeout ?? 3000));
                return await this.callEndpointWithErrorHandling(action, options);
            } else if (!options?.suppressErrors) {
                this.alertOnException(e as AxiosError<ClientResponse<T>>);
            }
        } finally {
            if (options?.cancellationKey) {
                this.removeCancellationToken(options.cancellationKey);
            }

            !options?.hideLoadingBar && bus.emit('PROGRESS_FINISH');
        }
    }

    protected calculateLoadPercentage = (event: AxiosProgressEvent): number => Math.round((event.loaded * 100) / event.total!);

    protected formatDate(date: Date | string | null | undefined, withTime = true): string {
        if (!date) return undefined as any;

        if (typeof date !== 'object') {
            date = new Date(date);
        }

        // return withTime ? format(date, 'yyyy-MM-dd\'T\'HH:mm:ss.SSSxxx') : date.toDateString();
        return withTime ? date.toISOString() : date.toDateString();
    }

    protected getContentDispositionFilename(response: { headers: Record<string, any> }): string | undefined {
        const disposition = response.headers['content-disposition'] ?? response.headers['Content-Disposition'];
        if (disposition && disposition.indexOf('attachment') !== -1) {
            const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
            const matches = filenameRegex.exec(disposition);
            if (matches != null && matches[1]) {
                return matches[1].replace(/['"]/g, '');
            }
        }
    }
}
