import { CustomerShipmentTypeViewObject, CustomerShipmentTypeCategoryViewObject, CustomerDeliveryFeeViewObject, NotificationViewObject, CustomerDeliveryInformationTemplateViewObject, GroupChatMessageViewObject, GroupChatViewObject } from '@/api';
import { TypedEmitter } from '@/core/event-emitter';
import { ProjectBusEvents } from '@/project/bus/index';
import { onBeforeUnmount } from 'vue';

interface BusEvents extends ProjectBusEvents {
    'PROGRESS_START': () => void;
    'PROGRESS_FINISH': () => void;
    'SIGNALR_RECONNECT': (connectionId?: string) => void;
    'SIGNALR_DISCONNECT': () => void;

    'CustomerShipmentTypeUpdated': (shipmentType: CustomerShipmentTypeViewObject) => void;
    'CustomerShipmentTypeCategoryUpdated': (shipmentTypeCategory: CustomerShipmentTypeCategoryViewObject) => void;
    'CustomerDeliveryFeesUpdated': (customerId: string, deliveryFees: CustomerDeliveryFeeViewObject[]) => void;

    'NotificationCreated': (notification: NotificationViewObject) => void;
    'NotificationUpdated': (notification: NotificationViewObject) => void;
    'NotificationsUpdated': (notifications: NotificationViewObject[]) => void;
    'NotificationDeleted': (notificationId: string) => void;
    
    'CustomerDeliveryInformationTemplateUpdated': (template: CustomerDeliveryInformationTemplateViewObject) => void;
    'CustomerDeliveryInformationTemplateRemoved': (template: CustomerDeliveryInformationTemplateViewObject) => void;

    'ChatMessageReceived': (message: GroupChatMessageViewObject) => void;
    'GroupChatCreatedOrUpdated': (chat: GroupChatViewObject) => void;
    'GroupChatRemoved': (chatId: string) => void;
}

const bus = new TypedEmitter<BusEvents>();

export function useBusEvent<U extends keyof BusEvents>(event: U, listener: BusEvents[U]) {
    bus.on(event, listener);

    onBeforeUnmount(() => {
        bus.off(event, listener);
    });
}

export function useBusEvents(events: { [K in keyof Partial<BusEvents>]: BusEvents[K] }) {
    Object.entries(events).forEach(([event, listener]) => {
        if (listener) {
            bus.on(event as keyof BusEvents, listener as BusEvents[keyof BusEvents]);
        }
    });

    onBeforeUnmount(() => {
        Object.entries(events).forEach(([event, listener]) => {
            if (listener) {
                bus.off(event as keyof BusEvents, listener as BusEvents[keyof BusEvents]);
            }
        });
    });
}

export default bus;
