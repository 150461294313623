import { readonly, ref } from 'vue';
import guidGenerator from '../guid/guidGenerator.service';
import { NotificationAlignment, NotificationPosition, NotificationTarget, NotificationType, NotificationViewObject } from '@/api';
import bus from '../bus';
import { mergeObject } from '../util/mergeObjects';
import notificationsApiService from '@/api-controllers/notificationsApi.service';

export interface Notification extends NotificationViewObject {
    duration: number;
    onCloseCallback?: () => void;
    onClickCallback?: () => void;
    icon?: string;
}

function notificationIsValid(notification: NotificationViewObject, target?: NotificationTarget) {
    const now = new Date();
    return notification.enabled && 
           (!target || notification.target.includes(target)) &&
           (!notification.activeFrom || new Date(notification.activeFrom) <= now) &&
           (!notification.activeTo || new Date(notification.activeTo) >= now);
}

class NotificationsService {
    private target?: NotificationTarget;

    private state = ref<Array<Notification>>([]);

    public notify({ id, title, message, type = NotificationType.Success, position = NotificationPosition.Top, alignment = NotificationAlignment.Right, duration = 3000, fullWidth = false, onCloseCallback, onClickCallback, icon }:
        { id?: string, title: string, message?: string | null, type?: (NotificationType | keyof typeof NotificationType), alignment?: (NotificationAlignment | keyof typeof NotificationAlignment), 
            position?: (NotificationPosition | keyof typeof NotificationPosition), duration?: number, fullWidth?: boolean, onCloseCallback?: () => void, onClickCallback?: () => void, icon?: string }) {

        id ??= guidGenerator.newGuid();

        this.state.value.unshift({
            id: id,
            title: title,
            message: message,
            type: type as NotificationType,
            position: position as NotificationPosition,
            alignment: alignment as NotificationAlignment,
            duration: duration,
            fullWidth: fullWidth,
            created: '',
            enabled: true,
            order: -1,
            target: undefined!,
            icon: icon,
            onCloseCallback: onCloseCallback,
            onClickCallback: onClickCallback,
        });
    }

    public get notifications() {
        return readonly(this.state);
    }

    public removeNotification(id: string) {
        const index = this.state.value.findIndex(x => x.id === id);
        if (index >= 0) {
            this.state.value.splice(index, 1);
        }
    }

    public async initializeTarget(target: keyof typeof NotificationTarget) {
        this.target = target as NotificationTarget;

        const result = await notificationsApiService.getActiveNotifications();
        if (result) {
            for (const notification of result.notifications) {
                if (!notificationIsValid(notification, this.target))
                    continue;

                this.notify({
                    id: notification.id,
                    title: notification.title,
                    message: notification.message!,
                    duration: 0,
                    alignment: notification.alignment,
                    position: notification.position,
                    type: notification.type,
                    fullWidth: notification.fullWidth,
                    onCloseCallback: async() => await notificationsApiService.markNotificationAsSeen(notification.id),
                });
            }
        }
    }

    public clear() {
        this.state.value = [];
    }

    constructor() {
        bus.on('LOGGED_OUT', () => this.clear());

        bus.on('NotificationDeleted', (id) => this.removeNotification(id));

        bus.on('NotificationCreated', (notification) => {
            const existing = this.state.value.find(x => x.id === notification.id);
            if (!existing && notificationIsValid(notification, this.target)) {
                this.notify({
                    id: notification.id,
                    title: notification.title,
                    message: notification.message!,
                    duration: 0,
                    alignment: notification.alignment,
                    position: notification.position,
                    type: notification.type,
                    fullWidth: notification.fullWidth,
                    onCloseCallback: async() => await notificationsApiService.markNotificationAsSeen(notification.id),
                });
            }
        });

        bus.on('NotificationUpdated', (notification) => {
            const existing = this.state.value.find(x => x.id === notification.id);
            if (existing) {
                if (notificationIsValid(notification, this.target))
                    mergeObject(existing, notification);
                else {
                    this.removeNotification(notification.id);
                }
            }
        });

        bus.on('NotificationsUpdated', (notifications) => {
            for (const notification of notifications) {
                const existing = this.state.value.find(x => x.id === notification.id);
                if (existing) {
                    if (notificationIsValid(notification, this.target))
                        mergeObject(existing, notification);
                    else {
                        this.removeNotification(notification.id);
                    }
                }
            }
        });
    }
}

export default new NotificationsService();
