import { App } from 'vue';

import { initDomVariables } from './dom/dom';
import { dictionaryConfig } from './dictionary/dictionary';
import iconConfig from './icons/icons.config';
import filterConfig from './filters/filters.config';
import formsConfig from './forms/forms.config';
import directivesConfig from './directives/directives.config';
import popoverConfig from './popover/popover.config';
import responsiveConfig from './responsive/responsive.config';
import draggableConfig from './drag-n-drop/draggable.config';
import richTextEditorConfig from './rich-text-editor/richTextEditor.config';
import colorPickerConfig from './color/picker/colerPicker.config';
import splitPanesconfig from './panes/splitPanes.config';
import swiperConfig from './swiper/swiper.config';
import contextMenuConfig from './context-menus';
import dateFnsConfig from './date-fns';
import genericConfig from './generic';
import sliderConfig from './slider';
import googleMapsLoader from './google/google-maps.loader';
import { startDictionaryMissingLabelsQueue } from './dictionary/dictionaryMissingLabelsQueue';

export default function config(app: App): void {
    dictionaryConfig(app);
    initDomVariables();
    iconConfig(app);
    filterConfig(app);
    formsConfig(app);
    popoverConfig(app);
    directivesConfig(app);
    responsiveConfig(app);
    draggableConfig(app);
    richTextEditorConfig(app);
    colorPickerConfig(app);
    splitPanesconfig(app);
    swiperConfig(app);
    sliderConfig(app);
    contextMenuConfig(app);
    dateFnsConfig();
    genericConfig(app);
    googleMapsLoader(app);

    // if () { // TODO: Make this conditional
    startDictionaryMissingLabelsQueue();
    // }
}
