import emitter from 'tiny-emitter/instance';

export type ListenerSignature<L> = {
    [E in keyof L]: (...args: any[]) => any;
};

export type DefaultListener = {
    [k: string]: (...args: any[]) => any;
};

export class TypedEmitter<L extends ListenerSignature<L> = DefaultListener> {
    on<U extends keyof L>(event: U, listener: L[U]) {
        return emitter.on(event as string, listener);
    }

    once<U extends keyof L>(event: U, listener: L[U]) {
        return emitter.once(event as string, listener);
    }

    emit<U extends keyof L>(event: U, ...args: Parameters<L[U]>) {
        return emitter.emit(event as string, ...args);
    }

    off<U extends keyof L>(event: U, listener: L[U]) {
        return emitter.off(event as string, listener);
    }
}