<template>
    <div class="progress-bar"
         :style="style">
    </div>
</template>

<script lang="ts">
import { defineComponent, computed, CSSProperties, reactive } from 'vue';
import bus from '@/core/bus';

export default defineComponent({
    name: 'ProgressBar',
    setup() {
        const avgProgressTimeMs = 300;

        let timer = 0;

        const state = reactive({
            tasksInProgress: 0,
            runtime: 0,
            totalTime: 0,
            progress: 0,
        });

        const reset = () => {
            if (!timer) return;

            clearInterval(timer);
            timer = 0;

            state.progress = 100;
            state.runtime = 0;
            state.totalTime = 0;

            setTimeout(() => {
                state.progress = 0;
            }, 300);
        };

        const handleProgress = () => {
            if (state.progress < 100) {
                if (state.runtime < state.totalTime) {
                    state.runtime += Math.random() * avgProgressTimeMs / 5;
                }
                state.progress = Math.min(state.tasksInProgress <= 0 ? 100 : 97.5, state.runtime / state.totalTime * 100);
            } else {
                reset();
            }
        };

        const handleProgressStart = () => {
            state.totalTime += avgProgressTimeMs;
            state.tasksInProgress += 1;

            if (!timer) {
                timer = setInterval(handleProgress, avgProgressTimeMs / 3) as any;
            }
        };

        const handleProgressFinish = () => {
            state.totalTime = Math.max(0, state.totalTime - avgProgressTimeMs);
            state.tasksInProgress = Math.max(0, state.tasksInProgress - 1);
        };

        bus.on('PROGRESS_START', handleProgressStart);
        bus.on('PROGRESS_FINISH', handleProgressFinish);

        const style = computed(() => {
            const width = Math.ceil((window.innerWidth - window.scrollbarWidth.value) * state.progress / 100);
            return {
                width: `${width}px`,
                opacity: state.progress ? 1 : 0,
            } as CSSProperties;
        });

        return {
            style,
        };
    },
});
</script>

<style lang="scss" scoped>
.progress-bar {
    position: fixed;
    top: 0;
    left: 0;
    height: 4px;
    pointer-events: none;
    background: linear-gradient(to right, theme("colors.blue.dark"), theme("colors.green.light"));
    transition: width 100ms linear;

    z-index: 100000;
}
</style>
