import { 
    BusinessEntityUserLoginHistoryViewModel, BusinessEntityUsersViewModel, BusinessEntityUserViewModel,
    CreateUserRequest, UpdateUserRequest,
    AddOrUpdateAllowableIpsRequest, 
    UserInformationMessageLogViewModel,
    AddOrUpdateUserInformationMessageLogRequest,
    UserInformationMessageLogViewObject,
    UserSmsHistoryEntryViewObject,
    ActiveDriversOverviewViewModel,
    DriverLogsRequest,
    BusinessEntityUserDriverLogsViewModel,
    BusinessEntityDeliveryHubUserViewObject,
    BusinessEntityUserWhatsAppHistoryEntryViewObject,
} from '@/api';
import { apiInstanceFactory } from '@/core/api/api.factory';
import { ApiBase } from '@/core/api/api.base';
import { AxiosProgressEvent } from 'axios';

const api = apiInstanceFactory();

class UsersService extends ApiBase {
    public async updateProfileImage(formFile: File, callback?: (event: AxiosProgressEvent, loadPercentage: number) => void): Promise<BusinessEntityUserViewModel | undefined> {
        return this.callEndpointWithErrorHandling(() => api.api.usersChangeProfileImageUpdate(
            { formFile },
            {
                headers: undefined!,
                onUploadProgress: (event: AxiosProgressEvent) => {
                    callback && callback(event, this.calculateLoadPercentage(event));
                },
            }));
    }

    public async updateProfileImageFor(userId: string, formFile: File, callback?: (event: AxiosProgressEvent, loadPercentage: number) => void): Promise<BusinessEntityUserViewModel | undefined> {
        return this.callEndpointWithErrorHandling(() => api.api.usersChangeUserProfileImageUpdate(
            {
                userId,
            }, 
            { formFile },
            {
                headers: undefined!,
                onUploadProgress: (event: AxiosProgressEvent) => {
                    callback && callback(event, this.calculateLoadPercentage(event));
                },
            }));
    }

    public async getUser(userId: string): Promise<BusinessEntityUserViewModel | undefined> {
        return this.callEndpointWithErrorHandling(() => api.api.usersGetUserList({ userId }));
    }

    public async getUsers(userIds?: string[]): Promise<BusinessEntityUsersViewModel | undefined> {
        return this.callEndpointWithErrorHandling(() => api.api.usersGetUsersCreate({
            userIds: userIds,
        }));
    }

    public async createUser(request: CreateUserRequest): Promise<BusinessEntityUserViewModel | undefined> {
        return this.callEndpointWithErrorHandling(() => api.api.usersCreateUserCreate(request));
    }

    public async updateUser(request: UpdateUserRequest): Promise<BusinessEntityUserViewModel | undefined> {
        return this.callEndpointWithErrorHandling(() => api.api.usersUpdateUserCreate(request));
    }

    public async updateSelf(request: UpdateUserRequest): Promise<BusinessEntityUserViewModel | undefined> {
        return this.callEndpointWithErrorHandling(() => api.api.usersUpdateSelfCreate(request));
    }

    public async deleteUser(userId: string, hardDelete: boolean): Promise<BusinessEntityUserViewModel | undefined> {
        return this.callEndpointWithErrorHandling(() => api.api.usersDeleteUserCreate({ userId, hardDelete }));
    }

    public async restoreUser(userId: string): Promise<BusinessEntityUserViewModel | undefined> {
        return this.callEndpointWithErrorHandling(() => api.api.usersRestoreUserCreate({ userId }));
    }

    public async blockUser(userId: string): Promise<BusinessEntityUserViewModel | undefined> {
        return this.callEndpointWithErrorHandling(() => api.api.usersBlockUserCreate({ userId }));
    }

    public async unblockUser(userId: string): Promise<BusinessEntityUserViewModel | undefined> {
        return this.callEndpointWithErrorHandling(() => api.api.usersUnblockUserCreate({ userId }));
    }

    public async resetPasswordForUser(userId: string, password?: string): Promise<BusinessEntityUserViewModel | undefined> {
        return this.callEndpointWithErrorHandling(() => api.api.usersResetPasswordForUserCreate({ userId, password }));
    }

    public async resendWelcomeMail(userId: string): Promise<BusinessEntityUserViewModel | undefined> {
        return this.callEndpointWithErrorHandling(() => api.api.usersResendWelcomeMailCreate({ userId }));
    }

    public async getUserLoginHistory(userId: string): Promise<BusinessEntityUserLoginHistoryViewModel | undefined> {
        return this.callEndpointWithErrorHandling(() => api.api.usersGetUserLoginHistoryCreate({ userId }));
    }

    public async updateAllowableIpsForUser(request: AddOrUpdateAllowableIpsRequest): Promise<BusinessEntityUserViewModel | undefined> {
        return this.callEndpointWithErrorHandling(() => api.api.usersUpdateAllowableIpsForUserCreate(request));
    }

    public async getUserInformationMessageLogs(from: Date, to: Date): Promise<UserInformationMessageLogViewModel | undefined> {
        return this.callEndpointWithErrorHandling(() => api.api.usersGetUserInformationMessageLogsList({
            from: this.formatDate(from),
            to: this.formatDate(to),
        }));
    }
    
    public async addOrUpdateUserInformationMessageLog(request: AddOrUpdateUserInformationMessageLogRequest): Promise<UserInformationMessageLogViewObject | undefined> {
        return this.callEndpointWithErrorHandling(() => api.api.usersAddOrUpdateUserInformationMessageLogCreate(request), {
            cancellationKey: 'addOrUpdateUserInformationMessageLog',
        });
    }

    public async getSmsHistory(userId: string, from: Date | null, to: Date | null, take: number): Promise<UserSmsHistoryEntryViewObject[] | undefined> {
        return this.callEndpointWithErrorHandling(() => api.api.usersGetSmsHistoryList({ 
            userId: userId, 
            from: this.formatDate(from),
            to: this.formatDate(to),
            take: take,
        }), {
            cancellationKey: 'getSmsHistory',
        });
    }

    public async getWhatsAppHistory(userId: string, from: Date | null, to: Date | null, take: number): Promise<BusinessEntityUserWhatsAppHistoryEntryViewObject[] | undefined> {
        return this.callEndpointWithErrorHandling(() => api.api.usersGetWhatsAppMessageHistoryList({ 
            userId: userId, 
            from: this.formatDate(from),
            to: this.formatDate(to),
            take: take,
        }), {
            cancellationKey: 'getWhatsAppHistory',
        });
    }

    public async getDriverConnections(userId: string): Promise<BusinessEntityDeliveryHubUserViewObject | undefined> {
        return this.callEndpointWithErrorHandling(() => api.api.usersGetDriverConnectionsList({ userId }), {
            cancellationKey: 'getDriverConnections',
        });
    }

    public async getActiveDriverOverview(): Promise<ActiveDriversOverviewViewModel | undefined> {
        return this.callEndpointWithErrorHandling(() => api.api.usersGetActiveDriverOverviewList(), {
            cancellationKey: 'getActiveDriverOverview',
        });
    }

    public async getDriverLogs(request: DriverLogsRequest): Promise<BusinessEntityUserDriverLogsViewModel | undefined> {
        if (request.driverLogIds.length === 0) return undefined;
        
        return this.callEndpointWithErrorHandling(() => api.api.usersGetDriverLogsCreate(request), {
            cancellationKey: 'getDriverLogs',
        });
    }

    public async isWhatsAppInstanceAuthorized(): Promise<boolean | undefined> {
        return this.callEndpointWithErrorHandling(() => api.api.communicationIsWhatsAppInstanceAuthorizedList(), {
            suppressErrors: true,
        });
    }

    public async getWhatsAppAuthorizationCode(): Promise<string | undefined> {
        return this.callEndpointWithErrorHandling(() => api.api.communicationGetWhatsAppAuthorizationCodeList(), {
            suppressErrors: true,
        });
    }
}

export default new UsersService();
