import { App } from 'vue';
import dictionary from '@/core/dictionary/dictionary';

declare module '@vue/runtime-core' {
    interface ComponentCustomProperties {
        $toTime: (timeInSecs: number, shortText?: boolean, hoursOnly?: boolean, hideSeconds?: boolean) => string;
        $toTimeFromNow: (date: Date | string, shortText?: boolean, hoursOnly?: boolean, hideSeconds?: boolean) => string;
    }
}

const MINUTE = 60;
const HOUR = MINUTE * 60;
const DAY = HOUR * 24;
const WEEK = DAY * 7;

export function toTime(timeInSecs: number, shortText = false, hoursOnly = false, hideSeconds = false): string {
    if (hideSeconds && timeInSecs < MINUTE) {
        return dictionary.get('LessThanAMinute');
    }

    const second = dictionary.get('Second');
    const seconds = dictionary.get('Seconds');
    const minute = dictionary.get('Minute');
    const minutes = dictionary.get('Minutes');
    const hour = dictionary.get('Hour');
    const hours = dictionary.get('Hours');
    const day = dictionary.get('Day');
    const days = dictionary.get('Days');
    const week = dictionary.get('Week');
    const weeks = dictionary.get('Weeks');

    const totalSecs = Math.floor(timeInSecs % MINUTE);
    const totalMinutes = Math.floor((timeInSecs % HOUR) / MINUTE);
    const totalHours = Math.floor((timeInSecs % DAY) / HOUR);
    const totalDays = Math.floor((timeInSecs % WEEK) / DAY);
    const totalWeeks = Math.floor(timeInSecs / WEEK);

    let ss = '';
    if (totalWeeks) ss = ss + totalWeeks + (shortText ? 'w' : totalWeeks > 1 ? ` ${weeks} ` : ` ${week} `);
    if (totalDays) ss = ss + totalDays + (shortText ? 'd' : totalDays > 1 ? ` ${days} ` : ` ${day} `);
    if (totalHours || hoursOnly) ss = ss + totalHours + (shortText ? 'h' : totalHours > 1 ? ` ${hours} ` : ` ${hour} `);
    if (!hoursOnly) {
        if (totalMinutes) ss = ss + totalMinutes + (shortText ? 'm' : totalMinutes > 1 ? ` ${minutes} ` : ` ${minute} `);
        else if (!hideSeconds && (totalSecs || (!totalDays && !totalHours && !totalMinutes && !totalWeeks))) {
            ss = ss + totalSecs + (shortText ? 's' : (totalSecs > 1 || totalSecs === 0) ? ` ${seconds}` : ` ${second}`);
        }
    }

    return ss.trim();
}

export function toTimeFromNow(date: Date | string, shortText = false, hoursOnly = false, hideSeconds = false) {
    date = typeof date === 'string' ? new Date(date) : date;

    const now = new Date();
    const diffSeconds = Math.floor((now.getTime() - date.getTime()) / 1000);
    return toTime(diffSeconds, shortText, hoursOnly, hideSeconds);
}

export default function config(app: App): void {
    app.config.globalProperties.$toTime = toTime;
    app.config.globalProperties.$toTimeFromNow = toTimeFromNow;
}
