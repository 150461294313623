import { App } from 'vue';
import numberFormatConfig from './numberFormatter.filter';
import dateFormatConfig from './dateFormatter.filter';
import timeFormatConfig from './timeFormatter.filter';
import roleFilterConfig from './role.filter';

export default function config(app: App): void {
    numberFormatConfig(app);
    dateFormatConfig(app);
    timeFormatConfig(app);
    roleFilterConfig(app);
}
