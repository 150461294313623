import { App, defineAsyncComponent } from 'vue';

const Copy = defineAsyncComponent(() => import('./Copy.vue'));
const Accordion = defineAsyncComponent(() => import('./Accordion.vue'));
const IntersectionObserver = defineAsyncComponent(() => import('./IntersectionObserver.vue'));
const CustomerShipmentData = defineAsyncComponent(() => import('./CustomerShipmentData.vue'));
const TypingAnimation = defineAsyncComponent(() => import('./TypingAnimation.vue'));

export default function config(app: App): void {
    app.component('Copy', Copy);
    app.component('Accordion', Accordion);
    app.component('IntersectionObserver', IntersectionObserver);
    app.component('CustomerShipmentData', CustomerShipmentData);
    app.component('TypingAnimation', TypingAnimation);
}
