import { App } from 'vue';
import { format, formatDistance } from 'date-fns';
import { da, enGB } from 'date-fns/locale';

declare module '@vue/runtime-core' {
    interface ComponentCustomProperties {
        $dateFormat: (src: Date | number | string, fmt?: string, correctForTimezone?: boolean) => string;
        $fromNow: (src: Date | number | string) => string;
    }
}

// export function adjustForTimezone(date: Date): Date {
//     const timeOffsetInMS: number = (date.getTimezoneOffset() * -1) * 60000;
//     date.setTime(date.getTime() + timeOffsetInMS);
//     return date;
// }

export function dateFormat(date: Date | number | string, fmt = 'dd.MM.yyyy HH:mm'): string {
    if (typeof date !== 'object') {
        date = new Date(date);
    } 

    if (fmt !== 'HH:mm' && fmt.endsWith('HH:mm') && date.getHours() === 0 && date.getMinutes() === 0) {
        fmt = fmt.slice(0, -5).trimEnd();
    }
    
    return format(date, fmt, {
        locale: window.languageCode === 'Da' ? da : enGB,
    });
}

export function fromNow(date: Date | number | string): string {
    date = (typeof date === 'string' || typeof date === 'number') ? new Date(date) : date;
    return formatDistance(
        date,
        new Date().getTime(),
        {
            addSuffix: true,
            includeSeconds: true,
            locale: window.languageCode === 'Da' ? da : enGB,
        });
}

export default function config(app: App): void {
    app.config.globalProperties.$dateFormat = dateFormat;
    app.config.globalProperties.$fromNow = fromNow;
}
