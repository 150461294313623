<template v-if="!!data">
    <vue-svgicon
        role="img"
        class="flex-shrink-0"
        :data="data"
        :fill="true"
        v-bind="$attrs"/>
</template>

<script lang="ts">
/*
    See possibilities for attribs here: https://mmf-fe.github.io/svgicon/en/guide/advanced.html#component
 */

import { defineComponent, ref } from 'vue';

export default defineComponent({
    name: 'CIcon',
    props: {
        name: {
            type: String,
            required: true,
        },
    },
    setup(props) {
        const data = ref(undefined);

        try {
            import(`../../core/icons/${props.name}.svg`).then(
                (svgModule) => (data.value = svgModule.default),
            );
        } catch (e) {
            console.error(e);
        }

        return { data };
    },
});
</script>
