<template>
    <component :is="tag" :key="label" class="whitespace-pre-line" v-html="label"/>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';

export default defineComponent({
    name: 'DictionaryLabel',
    props: {
        tag: {
            type: String as PropType<'span' | 'p'>,
            default: 'span',
        },
        label: {
            type: String,
            required: true,
        },
    },
});
</script>
