<template>
    <div :id="menu.id" ref="menuRef" class="context-menu" :style="style">
        <template v-for="(item, index) in menu.options.items" :key="index">
            <div v-if="index !== 0 && item.divided === 'up'" class="context-menu-divider"/>
            <button
                class="context-menu-button"
                @click="clickWrapper(item.onClick)"
                @mouseover="e => handleMouseOver(item, e)">
                <span class="whitespace-pre-line pointer-events-none" v-html="item.label">
                </span>
                <CIcon v-if="item.children?.length" class="flex-shrink-0 pointer-events-none" name="chevron-right"/>
            </button>
            <div v-if="index !== menu.options.items.length - 1 && item.divided === true || item.divided === 'down'" class="context-menu-divider"/>
        </template>
    </div>
</template>

<script lang="ts">
import { defineComponent, PropType, computed, CSSProperties, ref } from 'vue';
import contextMenuService, { ContextMenu, ContextMenuItem, MENU_WIDTH } from './contextMenu.service';

export default defineComponent({
    name: 'ContextMenu',
    props: {
        menu: {
            type: Object as PropType<ContextMenu>,
            required: true,
        },
    },
    setup(props) {
        const menuRef = ref<HTMLElement | null>(null);

        const style = computed<CSSProperties>(() => {
            if (menuRef.value) {
                const rect = menuRef.value.getBoundingClientRect();
                const isOutOfBounds = rect.bottom > window.innerHeight;

                if (isOutOfBounds) {
                    return {
                        width: `${MENU_WIDTH}px`,
                        bottom: '15px',
                        left: `${props.menu.options.x}px`,
                    };
                }
            }
    
            return {
                width: `${MENU_WIDTH}px`,
                top: `${props.menu.options.y}px`,
                left: `${props.menu.options.x}px`,
            };
        });

        const clickWrapper = (clickEvent?: (e?: MouseEvent | KeyboardEvent) => void) => {
            if (clickEvent) {
                clickEvent();
                contextMenuService.clearMenus();
            }
        };

        const handleMouseOver = (item: ContextMenuItem, e: MouseEvent) => {
            if (!item.children?.length) {
                contextMenuService.cleanupHiereachy(item);
                return;
            }

            const target = e.target as HTMLDivElement;
            if (target) {
                const rect = target.getBoundingClientRect();

                contextMenuService.showContextMenu({
                    ...props.menu.options,
                    parentId: props.menu.id,
                    parentItem: item,
                    parentMenu: props.menu,
                    items: item.children,
                    x: rect.right,
                    y: rect.top,
                });
            }
        };

        return {
            style,
            menuRef,
            clickWrapper,
            handleMouseOver,
        };
    },
});
</script>

<style lang="scss" scoped>
.context-menu {
    position: fixed;
    z-index: 999999;
    color: #000;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    background-color: #fff;
    border: 1px solid theme('colors.border');
    border-radius: 7px;
    max-height: 60vh;
    overflow: auto;
    
    &.context-menu-child {
        position: absolute;
        top: 0;
        left: 100%;
    }

    .context-menu-divider {
        width: 100%;
        flex-shrink: 0;
        padding: 3px 0;

        &::after {
            display: block;
            content: "";
            background-color: #f0f0f0;
            height: 2px;
        }
    }

    .context-menu-button {
        font-size: 12px;
        padding: 4px 4px 4px 10px;
        width: 100%;
        text-align: left;

        display: flex;
        align-items: center;
        justify-content: space-between;

        &:hover {
            background-color: rgba(#000, 0.3);
        }
    }
}
</style>
