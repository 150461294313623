import { ref, onMounted, onBeforeUnmount, watch, Ref, Slots } from 'vue';
export default function useContent(slots: Readonly<Slots>, popperNode: Ref<HTMLElement | null>, content: Ref<string>) {
    let observer: MutationObserver | null = null;
    const hasContent = ref(false);

    onMounted(() => {
        if (slots.content !== undefined || content.value) {
            hasContent.value = true;
        }

        if (!popperNode.value) return;

        observer = new MutationObserver(checkContent);
        observer.observe(popperNode.value, {
            childList: true,
            subtree: true,
        });
    });

    onBeforeUnmount(() => {
        if (observer) {
            observer.disconnect();
            observer = null;
        }
    });

    /**
   * Watch the content prop
   */
    watch(() => content.value, () => {
        hasContent.value = !!content.value || !!slots.content;
    });

    /**
   * Check the content slot
   */
    const checkContent = () => {
        hasContent.value = !!content.value || !!slots.content;
    };

    return {
        hasContent,
    };
}
