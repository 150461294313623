<template>
    <div class="toaster toaster-top toaster-top-right">
        <div class="toaster-slot">
            <transition-group name="list">
                <Notification v-for="notification in topRightNotifications" :key="notification.id" :notification="notification"/>
            </transition-group>
        </div>
    </div>
    <div class="toaster toaster-top toaster-top-left">
        <div class="toaster-slot">
            <transition-group name="list">
                <Notification v-for="notification in topLeftNotifications" :key="notification.id" :notification="notification"/>
            </transition-group>
        </div>
    </div>
    <div class="toaster toaster-top toaster-top-center">
        <div class="toaster-slot">
            <transition-group name="list">
                <Notification v-for="notification in topCenterNotifications" :key="notification.id" :notification="notification"/>
            </transition-group>
        </div>
    </div>

    <div class="toaster toaster-center toaster-center-right">
        <div class="toaster-slot">
            <transition-group name="list">
                <Notification v-for="notification in centerRightNotifications" :key="notification.id" :notification="notification"/>
            </transition-group>
        </div>
    </div>
    <div class="toaster toaster-center toaster-center-left">
        <div class="toaster-slot">
            <transition-group name="list">
                <Notification v-for="notification in centerLeftNotifications" :key="notification.id" :notification="notification"/>
            </transition-group>
        </div>
    </div>
    <div class="toaster toaster-center toaster-center-center">
        <div class="toaster-slot">
            <transition-group name="list">
                <Notification v-for="notification in centerCenterNotifications" :key="notification.id" :notification="notification"/>
            </transition-group>
        </div>
    </div>

    <div class="toaster toaster-bottom toaster-bottom-right">
        <div class="toaster-slot">
            <transition-group name="list">
                <Notification v-for="notification in bottomRightNotifications" :key="notification.id" :notification="notification"/>
            </transition-group>
        </div>
    </div>
    <div class="toaster toaster-bottom toaster-bottom-left">
        <div class="toaster-slot">
            <transition-group name="list">
                <Notification v-for="notification in bottomLeftNotifications" :key="notification.id" :notification="notification"/>
            </transition-group>
        </div>
    </div>
    <div class="toaster toaster-bottom toaster-bottom-center">
        <div class="toaster-slot">
            <transition-group name="list">
                <Notification v-for="notification in bottomRightNotifications" :key="notification.id" :notification="notification"/>
            </transition-group>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent, computed } from 'vue';
import notificationsService from './notifications.service';
import Notification from './Notification.vue';
import { NotificationPosition, NotificationAlignment } from '@/api';

export default defineComponent({
    name: 'NotificationsHost',
    components: {
        Notification,
    },
    setup() {
        const notifications = notificationsService.notifications;

        const topRightNotifications = computed(() => notifications.value.filter(x => x.position === NotificationPosition.Top && x.alignment === NotificationAlignment.Right));
        const topLeftNotifications = computed(() => notifications.value.filter(x => x.position === NotificationPosition.Top && x.alignment === NotificationAlignment.Left));
        const topCenterNotifications = computed(() => notifications.value.filter(x => x.position === NotificationPosition.Top && x.alignment === NotificationAlignment.Center));

        const bottomRightNotifications = computed(() => notifications.value.filter(x => x.position === NotificationPosition.Bottom && x.alignment === NotificationAlignment.Right));
        const bottomLeftNotifications = computed(() => notifications.value.filter(x => x.position === NotificationPosition.Bottom && x.alignment === NotificationAlignment.Left));
        const bottomCenterNotifications = computed(() => notifications.value.filter(x => x.position === NotificationPosition.Bottom && x.alignment === NotificationAlignment.Center));

        const centerRightNotifications = computed(() => notifications.value.filter(x => x.position === NotificationPosition.Center && x.alignment === NotificationAlignment.Right));
        const centerLeftNotifications = computed(() => notifications.value.filter(x => x.position === NotificationPosition.Center && x.alignment === NotificationAlignment.Left));
        const centerCenterNotifications = computed(() => notifications.value.filter(x => x.position === NotificationPosition.Center && x.alignment === NotificationAlignment.Center));

        return {
            topRightNotifications,
            topLeftNotifications,
            topCenterNotifications,
            bottomRightNotifications,
            bottomLeftNotifications,
            bottomCenterNotifications,
            centerRightNotifications,
            centerLeftNotifications,
            centerCenterNotifications,
        };
    },
});
</script>

<style lang="scss" scoped>
.list-enter-active,
.list-leave-active {
  transition: opacity 0.75s ease-in-out, transform .5s ease-out;
}
.list-enter-from,
.list-leave-to {
  opacity: 0;
}

.list-leave-to {
  transform: translateY(20px)
}

.toaster {
    position: fixed;
    left: .5rem;
    right: .5rem;
    margin: 0;
    padding: 0;
    height: 0;
    overflow: visible;
    z-index: 100000;

    &.toaster-top {
        top: 0;

        .toaster-slot {
            top: 0.5rem;
        }

        @media (max-width: 767px) {
            .toaster-slot {
                margin-left: auto;
                margin-right: auto;
            }
        }

        @media (min-width: 768px) {
            &.toaster-top-left {
                .toaster-slot {
                    margin-right: auto;
                }
            }

            &.toaster-top-right {
                .toaster-slot {
                    margin-left: auto;
                }
            }

            &.toaster-top-center {
                .toaster-slot {
                    margin-left: auto;
                    margin-right: auto;
                }
            }
        }
    }

    &.toaster-bottom {
        bottom: 0;

        .toaster-slot {
            bottom: 0.5rem;
        }

        @media (max-width: 767px) {
            .toaster-slot {
                margin-left: auto;
                margin-right: auto;
            }
        }

        @media (min-width: 768px) {
            &.toaster-bottom-left {
                .toaster-slot {
                    margin-right: auto;
                }
            }

            &.toaster-bottom-right {
                .toaster-slot {
                    margin-left: auto;
                }
            }

            &.toaster-bottom-center {
                .toaster-slot {
                    margin-left: auto;
                    margin-right: auto;
                }
            }
        }
    }

    &.toaster-center {
        top: 30%;
        left: 0;
        right: 0;

        .toaster-slot {
            top: 0.5rem;
        }

        @media (max-width: 767px) {
            .toaster-slot {
                margin-left: auto;
                margin-right: auto;
            }
        }

        @media (min-width: 768px) {
            &.toaster-center-left {
                .toaster-slot {
                    margin-right: auto;
                }
            }

            &.toaster-center-right {
                .toaster-slot {
                    margin-left: auto;
                }
            }

            &.toaster-center-center {
                .toaster-slot {
                    margin-left: auto;
                    margin-right: auto;
                }
            }
        }
    }

    .toaster-slot {
        display: block;
        position: absolute;
        max-width: 350px;
        width: 100%;
        left: 0;
        right: 0;
        padding: 0;
        margin: 0.5rem 2rem;
    }
}
</style>
