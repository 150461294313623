import { createApp } from 'vue';
import App from './App.vue';
import { App as AppType } from 'vue';
import router from './router/router.index';
import { RouteNames } from './router/router.routes';

import './styling/main.scss';

import coreConfig from '@/core';
import projectConfig from '@/project';
import componentsConfig from '@/components';
import dictionary from '@/core/dictionary/dictionary';
import loadingScreen from '@/core/dom/loadingScreen';
import invalidServerContext from '@/core/dom/invalidServerContext';
import authenticationService from './core/authentication/authentication.service';
import dictionaryApiService from './api-controllers/dictionaryApi.service';
import businessEntityApiService from './api-controllers/businessEntityApi.service';

import { LocalStorageService } from './core/storage/storage.service';
import { securityStore } from './store/security/security.store';

let app: AppType;

declare module '@vue/runtime-core' {
    interface ComponentCustomProperties {
        $routes: typeof RouteNames;
    }
}

(async function() {
    const theme = LocalStorageService.getItem('website-theme');
    if (theme) {
        document.body.classList.add(theme);
    }

    loadingScreen.show();

    window.serverContext = (await businessEntityApiService.getServerContext())!;
    if (!window.serverContext) {
        loadingScreen.hide();
        invalidServerContext.show();
        return;
    }

    await securityStore.init();

    const result = await dictionaryApiService.getDictionaryLabelsForArea();

    if (result?.labels?.length) {
        dictionary.init(result.labels);
    }
        
    await authenticationService.tryRefreshSession();

    app = createApp(App);
    
    coreConfig(app);
    projectConfig(app);
    componentsConfig(app);

    app.config.globalProperties.$routes = RouteNames;
    
    app.use(router);
    
    await router.isReady();
    
    app.mount('#app');

    loadingScreen.hide();
})();

export { app };
