import { Api, ApiConfig } from '@/api';
import { CustomHeaders, CustomRequestHeaders } from '@/core/constants';
import { LocalStorageKeys } from '@/project/localStorageKeys';
import { LocalStorageService } from '@/core/storage/storage.service';
import serverHubClient from '@/signalR/serverHub.client';
import HttpStatus from 'http-status-codes';
import axios, { InternalAxiosRequestConfig, AxiosResponse } from 'axios';
import bus from '@/core/bus';
import dictionary from '@/core/dictionary/dictionary';
import { getPlatform } from '@/infrastructure/environment';

const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
const isRunningInBrowser = getPlatform() === 'web';
const channel = isRunningInBrowser ? 'Web' : 'App';

const responseHandler = async(response: AxiosResponse): Promise<AxiosResponse<any>> => {
    const isTokenInvalid = response?.headers[CustomHeaders.TokenExpired];
    if (isTokenInvalid) {
        LocalStorageService.removeItem(LocalStorageKeys.TOKEN);
        bus.emit('LOGGED_OUT');
        return response;
    }

    const token = response?.headers[CustomHeaders.Token] as string;
    if (token)
        LocalStorageService.setItem(LocalStorageKeys.TOKEN, token);

    if (response.status === HttpStatus.UNAUTHORIZED && token) {
        // We got a 401 but a new token has been provided, retry the rejected request
        return await axios.request(response.config);
    }

    const replacedLanguage = response?.headers[CustomHeaders.LanguageReplaced] as string;
    if (replacedLanguage)
        dictionary.setActiveLanguageCode(replacedLanguage, false);

    return response;
};

const requestHandler = (config: InternalAxiosRequestConfig): InternalAxiosRequestConfig => {
    if (config.headers) {
        const authenticationToken = LocalStorageService.getItem(LocalStorageKeys.TOKEN);
        
        if (authenticationToken)
            config.headers['Authorization'] = `Bearer ${authenticationToken}`;

        if (serverHubClient.connectionId.value)
            config.headers[CustomRequestHeaders.SignalRConnectionId] = serverHubClient.connectionId.value;

        config.headers[CustomRequestHeaders.Timezone] = timeZone;
        config.headers[CustomRequestHeaders.Language] = window.languageCode;
        config.headers[CustomRequestHeaders.Domain] = window.domainName;
        config.headers[CustomRequestHeaders.Channel] = channel;

        if (window.appInfo)
            config.headers[CustomRequestHeaders.AppVersionId] = window.appInfo.version;

        if (window.serverContext)
            config.headers[CustomRequestHeaders.BusinessEntityId] = window.serverContext.businessEntity.id;
    }

    return config;
};

export const apiInstanceFactory = ({ format, ...axiosConfig }: ApiConfig<unknown> = { headers: undefined! }): Api<unknown> => {
    const api = new Api({
        baseURL: import.meta.env.VITE_API_BASE,
        secure: true,
        format,
        ...axiosConfig,
    });

    api.instance.interceptors.request.use(requestHandler);
    api.instance.interceptors.response.use(responseHandler);

    return api;
};
