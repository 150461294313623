import { App, defineAsyncComponent } from 'vue';

// Tabs cannot be loaded async as they need to mount and find their children
import Tab from './tabs/Tab.vue';
import Tabs from './tabs/Tabs.vue';

const CallToAction = defineAsyncComponent(() => import('./buttons/CallToAction.vue'));
const ToggleButton = defineAsyncComponent(() => import('./buttons/ToggleButton.vue'));
const Collapsible = defineAsyncComponent(() => import('./generic/Collapsible.vue'));
const Spinner = defineAsyncComponent(() => import('./loading-spinners/Spinner.vue'));
const SmartParagraph = defineAsyncComponent(() => import('./generic/SmartParagraph.vue'));
const InfoTile = defineAsyncComponent(() => import('./generic/InfoTile.vue'));
const ImageUploader = defineAsyncComponent(() => import('./generic/ImageUploader.vue'));
const UserProfileImage = defineAsyncComponent(() => import('./user/UserProfileImage.vue'));
const DragHandle = defineAsyncComponent(() => import('./generic/DragHandle.vue'));
const UserInfo = defineAsyncComponent(() => import('./user/UserInfo.vue'));
const TaskGridEditor = defineAsyncComponent(() => import('./tasks/grid/editors/TaskGridEditor.vue'));

export default function config(app: App): void {
    app.component('Tab', Tab);
    app.component('Tabs', Tabs);
    
    app.component('CallToAction', CallToAction);
    app.component('ToggleButton', ToggleButton);
    app.component('Collapsible', Collapsible);
    app.component('Spinner', Spinner);
    app.component('SmartParagraph', SmartParagraph);
    app.component('InfoTile', InfoTile);
    app.component('ImageUploader', ImageUploader);
    app.component('UserProfileImage', UserProfileImage);
    app.component('DragHandle', DragHandle);
    app.component('UserInfo', UserInfo);
    app.component('TaskGridEditor', TaskGridEditor);
}
