import { DictionaryLabelsViewModel, UpdateDictionaryLabelRequest, DictionaryLanguagesViewModel, DictionaryLanguageViewModel,
    DictionaryLabelsByLanguageViewModel, AddOrUpdateDictionaryLanguageRequest, DictionaryLabelByLanguageViewModel, MarkLabelAsMissingRequest,
} from '@/api';
import { apiInstanceFactory } from '@/core/api/api.factory';
import { ApiBase } from '@/core/api/api.base';

const api = apiInstanceFactory();

class DictionaryService extends ApiBase {
    //#region Business
    public async cacheBust(): Promise<boolean | undefined> {
        return this.callEndpointWithErrorHandling(() => api.api.dictionaryCacheBustCreate());
    }

    public async getSupportedLanguages(): Promise<DictionaryLanguagesViewModel | undefined> {
        return this.callEndpointWithErrorHandling(() => api.api.dictionaryGetSupportedLanguagesList());
    }

    public async getDictionaryLabelsForArea(area?: string): Promise<DictionaryLabelsViewModel  | undefined> {
        return this.callEndpointWithErrorHandling(() => api.api.dictionaryGetDictionaryLabelsForAreaList({
            area,
        }));
    }

    public async markLabelAsMissingRequest(request: MarkLabelAsMissingRequest): Promise<boolean | undefined> {
        return this.callEndpointWithErrorHandling(() => api.api.dictionaryMarkLabelAsMissingCreate(request));
    }
    //#endregion

    //#region Shipping
    public async getShippingDictionaryLabels(): Promise<DictionaryLabelsByLanguageViewModel | undefined> {
        return this.callEndpointWithErrorHandling(() => api.api.shippingDictionaryGetDictionaryLabelsList());
    }

    public async getShippingSupportedLanguages(): Promise<DictionaryLanguagesViewModel | undefined> {
        return this.callEndpointWithErrorHandling(() => api.api.shippingDictionaryGetSupportedLanguagesList());
    }

    public async getShippingDictionaryLabelsForLanguage(languageCode: string, area?: string): Promise<DictionaryLabelsViewModel | undefined> {
        return this.callEndpointWithErrorHandling(() => api.api.shippingDictionaryGetDictionaryLabelsForLanguageList({
            languageCode,
            area,
        }));
    }

    public async addOrUpdateShippingDictionaryLabel(request: UpdateDictionaryLabelRequest): Promise<DictionaryLabelByLanguageViewModel | undefined> {
        return this.callEndpointWithErrorHandling(() => api.api.shippingDictionaryAddOrUpdateDictionaryLabelCreate(request));
    }

    public async addOrUpdateShippingDictionaryLanguage(request: AddOrUpdateDictionaryLanguageRequest): Promise<DictionaryLanguageViewModel | undefined> {
        return this.callEndpointWithErrorHandling(() => api.api.shippingDictionaryAddOrUpdateDictionaryLanguageCreate(request));
    }

    //#endregion

    //#region Delivery
    public async getDeliveryDictionaryLabels(): Promise<DictionaryLabelsByLanguageViewModel | undefined> {
        return this.callEndpointWithErrorHandling(() => api.api.deliveryDictionaryGetDictionaryLabelsList());
    }

    public async getDeliverySupportedLanguages(): Promise<DictionaryLanguagesViewModel | undefined> {
        return this.callEndpointWithErrorHandling(() => api.api.deliveryDictionaryGetSupportedLanguagesList());
    }
    
    public async getDeliveryDictionaryLabelsForLanguage(languageCode: string, area?: string): Promise<DictionaryLabelsViewModel | undefined> {
        return this.callEndpointWithErrorHandling(() => api.api.deliveryDictionaryGetDictionaryLabelsForLanguageList({
            languageCode,
            area,
        }));
    }
    
    public async addOrUpdateDeliveryDictionaryLabel(request: UpdateDictionaryLabelRequest): Promise<DictionaryLabelByLanguageViewModel | undefined> {
        return this.callEndpointWithErrorHandling(() => api.api.deliveryDictionaryAddOrUpdateDictionaryLabelCreate(request));
    }
    
    public async addOrUpdateDeliveryDictionaryLanguage(request: AddOrUpdateDictionaryLanguageRequest): Promise<DictionaryLanguageViewModel | undefined> {
        return this.callEndpointWithErrorHandling(() => api.api.deliveryDictionaryAddOrUpdateDictionaryLanguageCreate(request));
    }
    //#endregion
}

export default new DictionaryService();
