import { App } from 'vue';
import { securityStore } from '@/store/security/security.store';

declare module '@vue/runtime-core' {
    interface ComponentCustomProperties {
        $hasAccess: (controller: string, action: string) => boolean;
    }
}

export default function config(app: App): void {
    app.config.globalProperties.$hasAccess = (controller, action) => securityStore.hasAccess(controller, action);
}
