import { App, ComputedRef } from 'vue';
import { userStore } from '@/store/user/user.store';

declare module '@vue/runtime-core' {
    interface ComponentCustomProperties {
        $isAdministrator: ComputedRef<boolean>;
        $isModerator: ComputedRef<boolean>;
        $isCarrier: ComputedRef<boolean>;
        $isStaff: ComputedRef<boolean>;
    }
}

export default function config(app: App): void {
    app.config.globalProperties.$isAdministrator = userStore.isAdministrator;
    app.config.globalProperties.$isModerator = userStore.isModerator;
    app.config.globalProperties.$isCarrier = userStore.isCarrier;
    app.config.globalProperties.$isStaff = userStore.isStaff;
}
