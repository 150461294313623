<template>
    <div
        ref="popperContainerNode"
        class="relative"
        :class="{'inline-block': !block}"
        :style="interactiveStyle"
        @mouseleave="hover && closePopper()">
        <div
            ref="triggerNode"
            @mouseover="hover && openPopper()"
            @click="togglePopper"
            @focus="openPopper"
            @keyup.esc="closePopper">
            <!-- The default slot to trigger the popper  -->
            <slot/>
        </div>
        <Transition name="fade">
            <div
                v-if="shouldShowPopper"
                ref="popperNode"
                class="popper"
                :class="{ 'hidden': !hasContent, 'pointer-events-none': disablePointerEvents, 'no-padding': noPadding }"
                @click="!interactive && closePopper()"
                @mouseover="hover && openPopper()"
                @mouseleave="hover && closePopper()">
                <Arrow v-if="arrow"/>
                <div class="content" :class="[contentClass, { showOverflow }]">
                    <slot name="content" :close="close" :is-open="modifiedIsOpen">
                        <span class="whitespace-pre-line" v-html="content"></span>
                    </slot>
                </div>
            </div>
        </Transition>
    </div>
</template>

<script lang="ts">
import { debounce } from 'lodash-es';
import {
    ref,
    computed,
    toRefs,
    watch,
    watchEffect,
    onMounted,
    defineComponent,
    CSSProperties,
} from 'vue';
import { usePopper, useContent, useClickAway } from '../composables';
import Arrow from './Arrow.vue';

export interface IPopper {
    open: () => void;
    openPopper: () => void;
    close: () => void;
    closePopper: () => void;
    togglePoppeR: () => void;
}

export default defineComponent({
    // eslint-disable-next-line vue/multi-word-component-names
    name: 'Popper',
    components: {
        Arrow,
    },
    props: {
        /**
         * Preferred placement (the "auto" placements will choose the side with most space.)
         */
        placement: {
            type: String,
            default: 'bottom',
        },
        /**
         * Disables automatically closing the popover when the user clicks away from it
         */
        disableClickAway: {
            type: Boolean,
            default: false,
        },
        /**
         * Offset in pixels along the trigger element
         */
        offsetSkid: {
            type: String,
            default: '0',
        },
        /**
         * Offset in pixels away from the trigger element
         */
        offsetDistance: {
            type: String,
            default: '10',
        },
        /**
         * Trigger the popper on hover
         */
        hover: {
            type: Boolean,
            default: false,
        },
        /**
         * Manually open/close the Popper, other events are ignored if this prop is set
         */
        show: {
            type: Boolean,
            default: null,
        },
        showOverflow: {
            type: Boolean,
            required: false,
            default: false,
        },
        /**
         * Disables the Popper. If it was already open, it will be closed.
         */
        disabled: {
            type: Boolean,
            default: false,
        },
        /**
         * Open the Popper after a delay (ms).
         */
        openDelay: {
            type: Number,
            default: 0,
        },
        /**
         * Close the Popper after a delay (ms).
         */
        closeDelay: {
            type: Number,
            default: 0,
        },
        /**
         * The z-index of the Popper.
         */
        zIndex: {
            type: [Number, String],
            default: 9999,
        },
        /**
         * Display an arrow on the popper
         */
        arrow: {
            type: Boolean,
            default: false,
        },
        /**
         * Stop arrow from reaching the edge of the popper
         */
        arrowPadding: {
            type: String,
            default: '0',
        },
        /**
         * If the Popper should be interactive, it will close when clicked/hovered if false
         */
        interactive: {
            type: Boolean,
            default: true,
        },
        /**
         * Lock the Popper into place, it will not flip dynamically when it runs out of space if true
         */
        locked: {
            type: Boolean,
            default: false,
        },
        /**
         * If the content is just a simple string, it can be passed in as a prop
         */
        content: {
            type: String,
            default: '',
        },
        width: {
            type: String,
            default: '300px',
        },
        contentClass: {
            type: String,
            default: '',
        },
        /**
         * Toggle the wrapper block or inline-block display type
         */
        block: {
            type: Boolean,
            default: false,
        },
        disablePointerEvents: {
            type: Boolean,
            default: false,
        },
        noPadding: {
            type: Boolean,
            default: false,
        },
    },
    // emits: {
    //     'open:popper': () => true,
    //     'close:popper': () => true,
    // },
    setup(props, { emit, slots }) {
        /* Delay execution for a set amount of milliseconds */
        // const delay = ms => new Promise(resolve => setTimeout(resolve, ms));

        const popperContainerNode = ref(null);
        const popperNode = ref<HTMLElement | null>(null);
        const triggerNode = ref<HTMLElement | null>(null);
        const modifiedIsOpen = ref(false);

        onMounted(() => {
            const children = slots.default!();

            if (children && children.length > 1) {
                return console.error(
                    `[Popper]: The <Popper> component expects only one child element at its root. You passed ${children.length} child nodes.`,
                );
            }
        });

        const {
            arrowPadding,
            closeDelay,
            content,
            disableClickAway,
            disabled,
            interactive,
            locked,
            offsetDistance,
            offsetSkid,
            openDelay,
            placement,
            show,
        } = toRefs(props);

        const { isOpen, open, close } = usePopper({
            arrowPadding,
            emit,
            locked,
            offsetDistance,
            offsetSkid,
            placement,
            popperNode,
            triggerNode,
            content,
        });

        const { hasContent } = useContent(slots, popperNode, content);

        const manualMode = computed(() => show.value !== null);
        const invalid = computed(() => disabled.value || !hasContent.value);
        const shouldShowPopper = computed(() => isOpen.value && !invalid.value);
        const enableClickAway = computed(
            () => !disableClickAway.value && !manualMode.value,
        );

        // Add an invisible border to keep the Popper open when hovering from the trigger into it
        const interactiveStyle = computed<CSSProperties>(() => {
            return {
                border: interactive.value
                    ? `border: ${offsetDistance.value}px solid transparent; margin: -${offsetDistance.value}px`
                    : undefined,
            };
        });

        const openPopperDebounce = debounce(open, openDelay.value);
        const closePopperDebounce = debounce(close, closeDelay.value);

        const openPopper = () => {
            if (invalid.value || manualMode.value) {
                return;
            }

            closePopperDebounce.cancel();
            openPopperDebounce();
        };

        const closePopper = () => {
            if (manualMode.value) {
                return;
            }

            openPopperDebounce.cancel();
            closePopperDebounce();
        };

        const togglePopper = () => {
            isOpen.value ? closePopper() : openPopper();
        };

        /**
         * If Popper is open, we automatically close it if it becomes
         * disabled or without content.
         */
        watch([hasContent, disabled], ([hasContent, disabled]) => {
            if (isOpen.value && (!hasContent || disabled)) {
                close();
            }
        });

        /**
         * In order to eliminate flickering or visibly empty Poppers due to
         * the transition when using the isOpen slot property, we need to return a
         * separate debounced value based on isOpen.
         */
        watch(isOpen, isOpen => {
            if (isOpen) {
                modifiedIsOpen.value = true;
            } else {
                debounce(() => {
                    modifiedIsOpen.value = false;
                }, 200);
            }
        });

        /**
         * Watch for manual mode.
         */
        watchEffect(() => {
            if (manualMode.value) {
                show.value ? openPopperDebounce() : closePopperDebounce();
            }
        });

        /**
         * Use click away if it should be enabled.
         */
        watchEffect(() => {
            if (enableClickAway.value) {
                useClickAway(popperContainerNode, closePopper);
            }
        });

        return {
            triggerNode,
            popperNode,
            popperContainerNode,
            interactiveStyle,
            shouldShowPopper,
            modifiedIsOpen,
            hasContent,
            togglePopper,
            open,
            openPopper,
            close,
            closePopper,
        };
    },
});
</script>

<style lang="scss" scoped>
.popper {
    transition: background-color 250ms ease-in-out;
    color: theme('colors.text');

    border-radius: var(--popper-theme-border-radius);
    border: solid var(--popper-theme-border-width) transparent;
    background-image: linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 0)),
                linear-gradient(103deg, var(--popper-theme-border-color-start) 0%, var(--popper-theme-border-color-end) 100%);
    background-origin: border-box;
    background-clip: content-box, border-box;
    box-shadow: 2px 10000px 1px theme('colors.elements') inset;

    z-index: 1000;
    min-width: v-bind(width);
    max-width: 90vw;

    .content {
        padding: var(--popper-theme-padding);
        max-height: 80vh;
        z-index: 1000000;

        &:not(.showOverflow) {
            overflow-y: auto;
            overflow-x: hidden;
        }
    }

    &.no-padding {
        padding: 0;
        min-width: fit-content;

        .content {
            padding: 0;
        }
    }
}

//   .popper:hover,
//   .popper:hover > #arrow::before {
//     background: var(--popper-theme-background-color-hover);
//   }

.inline-block {
    display: inline-block;
}

.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.2s ease;
}

.fade-enter-from,
.fade-leave-to {
    opacity: 0;
}
</style>
