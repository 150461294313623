const loadingScreen = document.createElement('div');

loadingScreen.innerHTML = `
    <div class="bg-background" style="position: fixed; display: flex; align-items: center; justify-content: center; z-index: 10000; top: 0; left: 0; right: 0; bottom: 0;" data-v-7ba5bd90="">
        <svg class="spinner green" viewBox="0 0 38 38" xmlns="http://www.w3.org/2000/svg" width="80" height="80" data-v-7ba5bd90="">
            <g fill="none" fill-rule="evenodd">
                <g transform="translate(1 1)" stroke-width="2">
                    <circle stroke-opacity=".25" cx="18" cy="18" r="18">
                    </circle>
                    <path d="M36 18c0-9.94-8.06-18-18-18">
                        <animateTransform attributeName="transform" type="rotate" from="0 18 18" to="360 18 18" dur="0.8s" repeatCount="indefinite">
                        </animateTransform>
                    </path>
                </g>
            </g>
        </svg>
    </div>`;

const show = () => {
    document.body.appendChild(loadingScreen);
};

const hide = () => {
    document.body.removeChild(loadingScreen);
};

export default {
    show,
    hide,
};
