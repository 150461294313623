import dictionaryApiService from '@/api-controllers/dictionaryApi.service';

let interval: number | undefined;

export function startDictionaryMissingLabelsQueue() {
    clearInterval(interval);
    
    interval = setInterval(async() => {
        for (let i = window.missingLabelsQueue.length - 1; i >= 0; i--) {
            await dictionaryApiService.markLabelAsMissingRequest(window.missingLabelsQueue[i]);
            window.missingLabelsQueue.splice(i, 1);
        }
    }, 5000);
}