import { BusinessEntityViewObject, HubConnectionViewObject, UserViewObject } from '@/api';
import { Store } from '@/core/store/store';
import serverHubClient from '@/signalR/serverHub.client';
import { computed, ComputedRef } from 'vue';
import notificationsService from '@/core/notifications/notifications.service';
import { mergeObject } from '@/core/util/mergeObjects';
import { getPlatform } from '@/infrastructure/environment';

type Hooks = 'logged-in' | 'logged-out' | 'session-refreshed';

type UserState<T extends Record<string, any>> = T & {
    identity: {
        user: UserViewObject | undefined;
    }
};

let websiteVersion = '';
const isRunningInBrowser = getPlatform() === 'web';

export abstract class UserStore<TState extends Record<string, any>> extends Store<UserState<TState>, Hooks> {
    constructor(protected readonly refreshWebsiteOnVersionChange: boolean) {
        super();

        serverHubClient.addEvent('BusinessEntityUpdated', (businessEntity: BusinessEntityViewObject) => {
            if (this.state.identity.user) {
                mergeObject(this.state.identity.user.businessEntity, businessEntity);
            }
        });

        serverHubClient.addEvent('ValidateVersion', (version: string, appVersionId?: string) => {
            if (isRunningInBrowser && websiteVersion && websiteVersion !== version) {
                if (refreshWebsiteOnVersionChange) {
                    notificationsService.notify({
                        title: 'Website opdatering',
                        message: 'Der er kommet en ny version af websitet. Siden bliver reloadet om 5 sekunder.',
                        duration: 0,
                    });

                    setTimeout(() => {
                        window.location.reload();
                    }, 5000);
                } else {
                    notificationsService.notify({
                        title: 'Website opdatering',
                        message: 'Der er kommet en ny version af websitet, vi anbefaler at refreshe siden.',
                        duration: 0,
                    });
                }
            }

            websiteVersion = version;
        });

        serverHubClient.addEvent('OnConnected', (userId: string, connection: HubConnectionViewObject) => {
            if (this.state.identity.user && this.state.identity.user.id === userId) {
                (this.state.identity.user.activeConnections ??= []).push(connection);
            }
        });

        serverHubClient.addEvent('OnConnectionUpdate', (userId: string, connection: HubConnectionViewObject) => {
            if (this.state.identity.user && this.state.identity.user.id === userId) {
                if (!this.state.identity.user.activeConnections?.length) {
                    this.state.identity.user.activeConnections = [connection];
                } else {
                    const existingConnection = this.state.identity.user.activeConnections.find(x => x.connectionId === connection.connectionId);
                    if (existingConnection) {
                        mergeObject(existingConnection, connection);
                    } else {
                        this.state.identity.user.activeConnections.push(connection);
                    }
                }
            }
        });

        serverHubClient.addEvent('OnDisconnected', (userId: string, connectionId: string) => {
            if (this.state.identity.user?.activeConnections?.length && this.state.identity.user.id === userId) {
                const index = this.state.identity.user.activeConnections.findIndex(x => x.connectionId === connectionId);
                if (index >= 0) {
                    this.state.identity.user.activeConnections.splice(index, 1);
                }
            }
        });
    }

    public setUser(user: UserViewObject): void {
        this.state.identity.user = user;
    }

    public get isLoggedIn(): ComputedRef<boolean> {
        return computed(() => !!this.state.identity.user);
    }

    public get user(): ComputedRef<UserViewObject> {
        return computed(() => this.state.identity.user!);
    }

    public get activeConnection(): ComputedRef<HubConnectionViewObject | null | undefined> {
        return computed(() => this.state.identity.user?.activeConnections?.find(x => x.connectionId === serverHubClient.connectionId.value));
    }

    public get isStaff(): ComputedRef<boolean> {
        return computed(() => this.state.identity.user?.isStaff ?? false);
    }

    public get businessEntity(): ComputedRef<BusinessEntityViewObject> {
        return computed(() => this.state.identity.user?.businessEntity!);
    }

    public clear(): void {
        this.state.identity.user = undefined;
    }
}
