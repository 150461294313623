import { 
    EndpointAccessViewObject, 
    UpdateEndpointAccessRequest, 
} from '@/api';
import { apiInstanceFactory } from '@/core/api/api.factory';
import { ApiBase } from '@/core/api/api.base';

const api = apiInstanceFactory();

class SecurityService extends ApiBase {
    public async getEndpointAccesses(): Promise<EndpointAccessViewObject[] | undefined> {
        return this.callEndpointWithErrorHandling(() => api.api.securityGetEndpointAccessesList());
    }

    public async updateEndpointAccess(request: UpdateEndpointAccessRequest): Promise<EndpointAccessViewObject | undefined> {
        return this.callEndpointWithErrorHandling(() => api.api.securityUpdateEndpointAccessCreate(request));
    }
}

export default new SecurityService();
