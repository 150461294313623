const div = document.createElement('div');

div.innerHTML = `
    <div class="bg-background" style="position: fixed; display: flex; align-items: center; justify-content: center; z-index: 10000; top: 0; left: 0; right: 0; bottom: 0;" data-v-7ba5bd90="">
        <div>
            <p class="text-20">
                '${window.domainName}' er ikke et valid subdomæne
            </p>
            <p class="text-16">
            Kontakt venligst iMendo teamet her <a href="https://imendo.dk/kontakt-os/" class="text-blue-500">iMendo.dk</a>
            </p>
        </div>
    </div>`;

const show = () => {
    document.body.appendChild(div);
};

const hide = () => {
    document.body.removeChild(div);
};

export default {
    show,
    hide,
};
