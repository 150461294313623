import {
    NotificationViewObject, NotificationViewModel, AddOrUpdateNotificationRequest,
    NotificationTarget,
} from '@/api';
import { apiInstanceFactory } from '@/core/api/api.factory';
import { ApiBase } from '@/core/api/api.base';

const api = apiInstanceFactory();

class NotificationsService extends ApiBase {
    public async getNotifications(): Promise<NotificationViewModel | undefined> {
        return this.callEndpointWithErrorHandling(() => api.api.notificationsGetNotificationsList());
    }

    public async getActiveNotifications(): Promise<NotificationViewModel | undefined> {
        return this.callEndpointWithErrorHandling(() => api.api.notificationsGetActiveNotificationsList({
            target: NotificationTarget.Business,
        }), { suppressErrors: true });
    }

    public async markNotificationAsSeen(notificationId: string): Promise<boolean | undefined> {
        return this.callEndpointWithErrorHandling(() => api.api.notificationsMarkNotificationAsSeenCreate({
            notificationId,
        }), { suppressErrors: true });
    }

    public async addOrUpdateNotification(request: AddOrUpdateNotificationRequest): Promise<NotificationViewObject | undefined> {
        return this.callEndpointWithErrorHandling(() => api.api.notificationsAddOrUpdateNotificationCreate(request));
    }

    public async addOrUpdateNotificationOrders(notificationIds: string[]): Promise<NotificationViewModel | undefined> {
        return this.callEndpointWithErrorHandling(() => api.api.notificationsAddOrUpdateNotificationOrdersCreate(notificationIds));
    }
    
    public async deleteNotification(notificationId: string): Promise<NotificationViewObject | undefined> {
        return this.callEndpointWithErrorHandling(() => api.api.notificationsDeleteNotificationDelete({ notificationId }));
    }

    public async rePublishNotification(notificationId: string): Promise<boolean | undefined> {
        return this.callEndpointWithErrorHandling(() => api.api.notificationsRePublishNotificationCreate({ notificationId }));
    }
}

export default new NotificationsService();
