import { LoginRequest, LoginResponse, UserViewObject } from '@/api';
import authenticationApiService from '@/api-controllers/authenticationApi.service';
import { LocalStorageKeys } from '@/project/localStorageKeys';
import router from '@/router/router.index';
import { RouteMetaExtended, RouteNames } from '@/router/router.routes';
import serverHubClient from '@/signalR/serverHub.client';
import { userStore } from '@/store/user/user.store';
import bus from '@/core/bus';
import { LocalStorageService } from '../storage/storage.service';

class AuthenticationService {
    public async tryRefreshSession(): Promise<boolean> {
        const authenticationToken = LocalStorageService.getItem(LocalStorageKeys.TOKEN);
        if (!authenticationToken) 
            return false;

        const result = await authenticationApiService.refreshSession();
        if (result) {
            return await this.refreshSession(result.user, result.token);
        }

        await this.logout();
        return false;
    }

    public async refreshSession(user: UserViewObject, token: string): Promise<boolean> {
        try {
            await serverHubClient.stop();
            LocalStorageService.setItem(LocalStorageKeys.TOKEN, token);
            userStore.setUser(user);
            await serverHubClient.start();
            bus.emit('LOGGED_IN');
            return true;
        }
        catch (e) {
            console.error(e);
            return false;
        }
    }
    
    // public async loginPrompt(navigateToAfterSuccesfulLogin?: keyof typeof RouteNames) {
    //     await dialogService.showModal({
    //         component: defineAsyncComponent({ loader: () => import('@/components/authentication/Login.vue') }),
    //     }, {
    //         size: 'xs',
    //     });

    //     if (navigateToAfterSuccesfulLogin && authenticationToken.value) {
    //         await router.push({ name: navigateToAfterSuccesfulLogin });
    //     }
    // }

    public async login(request: LoginRequest): Promise<LoginResponse | undefined> {
        const result = await authenticationApiService.login(request);
        if (result) {
            window.domainName = request.domain;
            LocalStorageService.setItem(LocalStorageKeys.TOKEN, result.token);
            userStore.setUser(result.user);
            await serverHubClient.start();
            bus.emit('LOGGED_IN');
        }
        return result;
    }

    public async logout() {
        await serverHubClient.stop();
        await authenticationApiService.logout();
        
        LocalStorageService.removeItem(LocalStorageKeys.TOKEN);
        userStore.clear();

        const currentRoute = router.currentRoute.value;
        const meta = currentRoute.meta as RouteMetaExtended;
        if (!meta.allowAnonymous) {
            await router.push({ name: RouteNames.HOME });
        }

        bus.emit('LOGGED_OUT');
    }

    constructor() {
        serverHubClient.addEvent('LogoutUser', async() => {
            try {
                await this.logout();
            } finally {
                router.push({ name: RouteNames.AUTH_LOGIN }).finally(() => window.location.reload());
            }
        });

        serverHubClient.addEvent('RefreshSession', async() => {
            await this.tryRefreshSession();
        });
    }
}

export default new AuthenticationService();
