<template>
    <div class="notification" :class="[ notification.type.toLowerCase(), { 'fullWidth': notification.fullWidth, 'cursor-pointer': !!notification.onClickCallback } ]" @click="notification.onClickCallback?.()">
        <div class="notification-content">
            <header class="notification-header">
                <CIcon :name="notification.icon ?? icon"
                       height="25"
                       width="25"
                       class="flex-shrink-0 mr-5"
                       fill/>
                <div v-html="notification.title">
                </div>
                <div class="flex-shrink-0 ml-auto">
                    <InputClear class="input-clear" width="16" height="16" @clear="close"/>
                </div>
            </header>
            <div v-if="notification.message" class="notification-body" v-html="notification.message">
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent, onMounted, PropType } from 'vue';
import notificationService, { Notification } from './notifications.service';
import { NotificationType } from '@/api';

export default defineComponent({
    name: 'Notification',
    props: {
        notification: {
            type: Object as PropType<Notification>,
            required: true,
        },
    },
    setup(props) {
        const close = () => {
            props.notification.onCloseCallback?.();
            notificationService.removeNotification(props.notification.id);
        };

        if (props.notification.duration > 0) {
            onMounted(() => {
                setTimeout(() => {
                    close();
                }, props.notification.duration);
            });
        }

        let icon: string;
        switch (props.notification.type) {
        case NotificationType.Success:
            icon = 'check-circle';
            break;
        case NotificationType.Info:
            icon = 'information';
            break;
        case NotificationType.Warning:
            icon = 'alert';
            break;
        case NotificationType.Error:
            icon = 'alert-decagram';
            break;
        }

        return {
            close,
            icon,
        };
    },
});
</script>

<style lang="scss" scoped>
.notification {
    display: block;
    position: relative;
    max-width: 350px;
    backface-visibility: hidden;
    background-clip: padding-box;
    z-index: 1;
    border-radius: .35rem;

    &:not(:last-child) {
        margin-bottom: .75rem;
    }

    &.success {
        @apply bg-green-dark;
    }

    &.info {
        @apply bg-white text-black;
    }

    &.warning {
        @apply bg-yellow-600;
    }

    &.error {
        @apply bg-red-dark;
    }

    .notification-content {
        @apply shadow-lg;

        .notification-header {
            @apply px-4 py-4 text-13 font-bold flex items-center;
            border-bottom: 1px solid rgba(0,0,0,.1);
        }

        .notification-body {
            @apply px-4 py-4 text-12 break-words whitespace-pre-line;
        }
    }
}
</style>
