import type { Config } from 'tailwindcss';
import themer from 'tailwindcss-themer';

export default {
    content: [
        './index.html',
        './src/**/*.{vue,js,ts,jsx,tsx}',
    ],
    safelist: [
        {
            pattern: /col-span-(1|2|3|4|5|6|7|8|9|10|11|12)/,
        },
    ],
    mode: 'jit',
    theme: {
        fontSize: {
            9: '0.9rem',
            10: '1rem',
            11: '1.1rem',
            12: '1.2rem',
            13: '1.3rem',
            14: '1.4rem',
            15: '1.5rem',
            16: '1.6rem',
            17: '1.7rem',
            18: '1.8rem',
            20: '2rem',
            22: '2.2rem',
            24: '2.4rem',
            26: '2.6rem',
            28: '2.8rem',
            32: '3.2rem',
            38: '3.8rem',
            42: '4.2rem',
            56: '5.6rem',
            80: '8rem',
            96: '9.6rem',
        },
        screens: {
            xs: '0px',
            sm: '640px',
            md: '768px',
            lg: '1025px',
            xl: '1300px',
            xxl: '1500px',
        },
        zIndex: {
            menu: '1000',
            leftMenu: '1000',
            rightMenu: '1000',
            topMenu: '1500',
            dialog: '2000',
            confirmationDialog: '3000',
            spinner: '20000',
        },
        extend: {
            maxWidth: {
                sm: '32rem',
                md: '48rem',
                lg: '60rem',
            },
            margin: {
                15: '3.75rem',
                18: '4.5rem',
                26: '6.5rem',
                50: '5rem',
                80: '8rem',
                90: '9rem',
                95: '9.5rem',
                100: '10rem',
            },
            padding: {
                15: '1.5rem',
                50: '5rem',
                80: '8rem',
            },
        },
    },
    plugins: [
        themer({
            defaultTheme: {
                extend: {
                    colors: {
                        blue: {
                            dark: '#118ab2',
                            primary: '#118ab2',
                        },
                        green: {
                            light: '#249269',
                            dark: '#166534',
                        },
                        red: {
                            light: '#ED217C',
                            dark: '#DC2626',
                        },
                        yellow: {
                            dark: 'rgb(234 179 8)',
                        },
                        orange: {
                            primary: '#FFAA00',
                        },
                        text: '#fff',
                        background: '#151a30',
                        navItemExpanded: '#064e98',
                        navItemActive: '#1B213B',
                        navigation: '#1B213B',
                        elements: '#222b45',
                        border: '#101426',
                        shadow: '#1a1f33',
                        backdrop: '#1a1f33',
                        modalBackground: '#222b45',
                        grid: {
                            header: '#1e3a8a',
                            headerText: '#fff',
                            evenRow: '#222b45',
                            oddRow: '#151a30',
                            hover: '#475569',
                            dragging: '#1e3a8a',
                            type: {
                                pickup: '#0e7490',
                                pickupText: '#fff',
                                delivery: '#6d28d9',
                                deliveryText: '#fff',
                                direct: '#c89000',
                                directText: '#000',
                                nextDay: '#0284c7',
                                nextDayText: '#fff',
                            },
                        },
                        tabs: {
                            item: '#1e3a8a',
                            itemActive: '#1d4ed8',
                        },
                        cta: {
                            default: {
                                text: '#fff',
                                background: '#1e3a8a',
                            },
                        },
                        calendar: {
                            header: '#06457C',
                            elements: '#06457C',
                            elementsBorder: '#035899',
                            top: '#083563',
                            left: '#083563',
                            indicator: '#FFAA00',
                            entries: '#006CB7',
                        },
                    },
                },
            },
            themes: [
                {
                    name: 'dark-theme',
                    extend: {
                        colors: {
                            blue: {
                                dark: '#118ab2',
                                primary: '#118ab2',
                            },
                            green: {
                                light: '#249269',
                                dark: '#166534',
                            },
                            red: {
                                light: '#ED217C',
                                dark: '#DC2626',
                            },
                            yellow: {
                                dark: 'rgb(234 179 8)',
                            },
                            orange: {
                                primary: '#FFAA00',
                            },
                            text: '#fff',
                            background: '#151a30',
                            navItemExpanded: '#064e98',
                            navItemActive: '#1B213B',
                            navigation: '#1B213B',
                            elements: '#222b45',
                            border: '#101426',
                            shadow: '#1a1f33',
                            backdrop: '#1a1f33',
                            modalBackground: '#222b45',
                            grid: {
                                header: '#1e3a8a',
                                headerText: '#fff',
                                evenRow: '#222b45',
                                oddRow: '#151a30',
                                hover: '#475569',
                                dragging: '#1e3a8a',
                                type: {
                                    pickup: '#0e7490',
                                    pickupText: '#fff',
                                    delivery: '#6d28d9',
                                    deliveryText: '#fff',
                                    direct: '#c89000',
                                    directText: '#000',
                                    nextDay: '#0284c7',
                                    nextDayText: '#fff',
                                },
                            },
                            tabs: {
                                item: '#1e3a8a',
                                itemActive: '#1d4ed8',
                            },
                            cta: {
                                default: {
                                    text: '#fff',
                                    background: '#1e3a8a',
                                },
                            },
                            calendar: {
                                header: '#222b45',
                                elements: '#222b45',
                                elementsBorder: '#101426',
                                top: '#1B213B',
                                left: '#1B213B',
                                indicator: '#FFAA00',
                                entries: '#006CB7',
                            },
                        },
                    },
                },
                {
                    name: 'blue-theme',
                    extend: {
                        colors: {
                            blue: {
                                dark: '#118ab2',
                                primary: '#118ab2',
                            },
                            green: {
                                light: '#249269',
                                dark: '#166534',
                            },
                            red: {
                                light: '#ED217C',
                                dark: '#DC2626',
                            },
                            yellow: {
                                dark: 'rgb(234 179 8)',
                            },
                            orange: {
                                primary: '#FFAA00',
                            },
                            text: '#fff',
                            background: '#083563',
                            navItemExpanded: '#064e98',
                            navItemActive: '#006CB7',
                            navigation: '#006CB7',
                            elements: '#06457C',
                            border: '#101426',
                            shadow: '#1a1f33',
                            backdrop: '#1a1f33',
                            modalBackground: '#06457C',
                            grid: {
                                header: '#006CB7',
                                headerText: '#fff',
                                evenRow: '#06457C',
                                oddRow: '#083563',
                                hover: '#006CB7',
                                dragging: '#3797CF',
                                type: {
                                    pickup: '#0e7490',
                                    pickupText: '#fff',
                                    delivery: '#6d28d9',
                                    deliveryText: '#fff',
                                    direct: '#c89000',
                                    directText: '#000',
                                    nextDay: '#0284c7',
                                    nextDayText: '#fff',
                                },
                            },
                            tabs: {
                                item: '#083563',
                                itemActive: '#006CB7',
                            },
                            cta: {
                                default: {
                                    text: '#fff',
                                    background: '#F99500',
                                },
                            },
                            calendar: {
                                header: '#06457C',
                                elements: '#083563',
                                elementsBorder: '#035899',
                                top: '#083563',
                                left: '#083563',
                                indicator: '#FFAA00',
                                entries: '#006CB7',
                            },
                        },
                    },
                },
                {
                    name: 'white-theme',
                    extend: {
                        colors: {
                            blue: {
                                dark: '#118ab2',
                                primary: '#118ab2',
                            },
                            green: {
                                light: '#249269',
                                dark: '#166534',
                            },
                            red: {
                                light: '#ED217C',
                                dark: '#DC2626',
                            },
                            yellow: {
                                dark: 'rgb(234 179 8)',
                            },
                            orange: {
                                primary: '#FFAA00',
                            },
                            text: '#000',
                            background: '#f5f6fa',
                            navItemExpanded: '#064e98',
                            navItemActive: '#cbd5e1',
                            navigation: '#cbd5e1',
                            elements: '#fff',
                            border: '#101426',
                            shadow: '#1a1f33',
                            backdrop: '#1a1f33',
                            modalBackground: '#fff',
                            grid: {
                                header: '#f5f5f5',
                                headerText: '#000',
                                evenRow: '#fff',
                                oddRow: '#fff',
                                hover: '#dad8d6',
                                dragging: '#d1d5db',
                                type: {
                                    pickup: '#60a5fa',
                                    pickupText: '#000',
                                    delivery: '#2dd4bf',
                                    deliveryText: '#000',
                                    direct: '#c89000',
                                    directText: '#000',
                                    nextDay: '#0284c7',
                                    nextDayText: '#fff',
                                },
                            },
                            tabs: {
                                item: '#083563',
                                itemActive: '#006CB7',
                            },
                            cta: {
                                default: {
                                    text: '#fff',
                                    background: '#0F6CBD',
                                },
                            },
                            popper: {
                                
                            },
                            calendar: {
                                header: '#CFDEE8',
                                elements: '#fff',
                                elementsBorder: '#CFDEE8',
                                top: '#DBE6EE',
                                left: '#DBE6EE',
                                indicator: '#FFAA00',
                                entries: '#F5F9FC',
                            },
                        },
                    },
                },
            ],
        }),
    ],
} satisfies Config;