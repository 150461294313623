import { BusinessEntityUserRole } from '@/api';
import { enumToArray } from '@/core/constants';
import { computed, ComputedRef, watch } from 'vue';
import { UserStore } from '@/core/store/user/user.store';
import usersApiService from '@/api-controllers/usersApi.service';

interface State extends Record<string, unknown> {
    isWhatsAppInstanceAuthorized: boolean
}

const roleArray = enumToArray(BusinessEntityUserRole);

class BusinessUserStore extends UserStore<State> {
    protected override data() {
        return {
            identity: {
                user: undefined,
            },
            isWhatsAppInstanceAuthorized: false,
        };
    }

    public hasRole(role: BusinessEntityUserRole): boolean {
        return !!this.state.identity.user && roleArray.indexOf(this.state.identity.user.role) >= roleArray.indexOf(role);
    }

    public get isAdministrator(): ComputedRef<boolean> {
        return computed(() => this.hasRole(BusinessEntityUserRole.Administrator));
    }

    public get isModerator(): ComputedRef<boolean> {
        return computed(() => this.hasRole(BusinessEntityUserRole.Moderator));
    }

    public get isCarrier(): ComputedRef<boolean> {
        return computed(() => this.hasRole(BusinessEntityUserRole.Carrier));
    }

    public get isWhatsAppInstanceAuthorized() {
        return computed(() => this.state.isWhatsAppInstanceAuthorized);
    }

    public async checkIfWhatsAppInstanceAuthorized() {
        const result = await usersApiService.isWhatsAppInstanceAuthorized();
        this.state.isWhatsAppInstanceAuthorized = !!result;
    }

    constructor() {
        super(false);
        
        watch([() => this.state.identity.user?.whatsAppAccessToken, () => this.state.identity.user?.whatsAppInstanceId], async() => {
            if (this.state.identity.user?.whatsAppAccessToken && this.state.identity.user.whatsAppInstanceId) {
                await this.checkIfWhatsAppInstanceAuthorized();
            } else {
                this.state.isWhatsAppInstanceAuthorized = false;
            }
        });
    }
}

export const userStore = new BusinessUserStore();
