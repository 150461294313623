import { isValid } from 'date-fns';

export const createLocalStorageKey = (key: string) => `${window.domainName}_${key}`;

class BaseStorage {
    readonly store: Storage;

    constructor(store: Storage) {
        this.store = store;
    }

    public isImplemented(): boolean {
        return true;
    }

    public getItem<T extends string = string>(key: string): T | undefined {
        return this.store.getItem(createLocalStorageKey(key)) as T | undefined;
    }

    public getInt(key: string): number | null {
        const val = this.store.getItem(createLocalStorageKey(key));
        return val == null || isNaN(val as any) ? null : parseInt(val, 10);
    }

    public getBool(key: string): boolean | null {
        const val = this.store.getItem(createLocalStorageKey(key));
        return val == null ? null : val.toLowerCase() === 'true';
    }

    public getFloat(key: string): number | null {
        const val = this.store.getItem(createLocalStorageKey(key));
        return val == null || isNaN(val as any) ? null : parseFloat(val);
    }

    public getDate(key: string, resetTime = false): Date | null {
        try {
            const val = this.store.getItem(createLocalStorageKey(key));
            const date = val == null || !isValid(Date.parse(val)) ? null : new Date(val);
            resetTime && date?.setHours(0, 0, 0, 0);
            return date;
        } catch (e) {
            console.warn(e);
            this.store.removeItem(createLocalStorageKey(key));
            return null;
        }
    }

    public getItemAs<T>(key: string): T | null {
        const item = this.store.getItem(createLocalStorageKey(key));
        try {
            return item !== null ? (JSON.parse(item) as T) : null;
        } catch (err) {
            console.log(err);
            this.store.removeItem(createLocalStorageKey(key));
            return null;
        }
    }

    public setItemAs<T>(key: string, data: T): void {
        try {
            this.store.setItem(createLocalStorageKey(key), JSON.stringify(data));
        } catch (ex) {
            console.log(ex);
        }
    }

    public setItem(key: string, value: any): boolean {
        try {
            this.store.setItem(createLocalStorageKey(key), value);
            return true;
        } catch (ex) {
            return false;
        }
    }

    public removeItem(key: string): void {
        return this.store.removeItem(createLocalStorageKey(key));
    }

    public clear(): void {
        return this.store.clear();
    }
}

export const LocalStorageService = new BaseStorage(window.localStorage);
export const SessionStorageService = new BaseStorage(window.sessionStorage);
